/* eslint-disable i18next/no-literal-string */
import { DateTime } from 'luxon';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { PatientResponse } from '../api/generated';
import { i18n, useTranslation } from '../i18n';

/**
 * Formats a date in YYYY-MM-DD format to a string in the format.
 */
export const formatDate = (MyDate: Date | DateTime | moment.Moment | string | null) => {
  if (!MyDate) {
    return '';
  }
  if (MyDate instanceof DateTime) {
    MyDate = MyDate.toJSDate();
  }
  if (moment.isMoment(MyDate)) {
    MyDate = MyDate.toDate();
  }
  if (typeof MyDate === 'string') {
    MyDate = parseLocalDate(MyDate);
  }

  return (
    MyDate.getFullYear() + '-' + ('0' + (MyDate.getMonth() + 1)).slice(-2) + '-' + ('0' + MyDate.getDate()).slice(-2)
  );
};

/**
 * Formats a long-form date:
 *
 * > formatDateLongForm('2022-10-14')
 * 'October 14, 2022'
 */
export const formatDateLongForm = (locale: string, d: string | null | undefined) => {
  if (!d) {
    return '–';
  }
  const date = parseLocalDate(d);
  return date.toLocaleDateString(locale, { year: 'numeric', month: 'long', day: 'numeric' });
};

/**
 * Formats a date in short-month-and-day "Jan 3" format.
 */
export const formatDateMonthDayShort = (locale: string, d: Date | string | null | undefined) => {
  if (!d) {
    return '–';
  }
  const date = parseLocalDate(d);
  return date.toLocaleDateString(locale, { month: 'short', day: 'numeric' });
};

/*
 * Formats a date range in short-month-and-day "Jan 3-6" (or "Jan 30-Feb 2") format.
 */
export function formatDateRangeShort(d1: Date | string | null | undefined, d2: Date | string | null): string {
  const locale = i18n.language;
  if (!d1 || !d2) {
    return '–';
  }
  const date1 = parseLocalDate(d1);
  const date2 = parseLocalDate(d2);
  return (date1.getMonth() === date2.getMonth())
    ? `${formatDateMonthDayShort(locale, date1)}-${date2.getDate()}`
    : `${formatDateMonthDayShort(locale, date1)}-${formatDateMonthDayShort(locale, date2)}`;
}

/**
 * Formats a date abbreviating the month.
 *
 * > formatDateShortMonth('2022-10-14')
 * 'Oct. 14, 2022'
 */
export const formatDateShortMonth = (locale: string, d: Date | string | null | undefined | DateTime) => {
  if (!d) {
    return '–';
  }
  const date = parseLocalDate(d);
  return date.toLocaleDateString(locale, { year: 'numeric', month: 'short', day: 'numeric' });
};

// TODO: Refactor so that we don't need this function
export function formatDateShortMonthLocalized(d: Date | string) {
  const locale = i18n.language;
  if (!d) {
    return '–';
  }
  const date = parseLocalDate(d);
  return date.toLocaleDateString(locale, { year: 'numeric', month: 'short', day: 'numeric' });
}

/**
 * Formats a date abbreviating the month.
 *
 * > formatDateLongMonth('2022-10-14')
 * 'October 14, 2022'
 */
export const formatDateLongMonth = (locale: string, d: Date | string | null | undefined | DateTime) => {
  if (!d) {
    return '–';
  }
  const date = parseLocalDate(d);
  return date.toLocaleDateString(locale, { year: 'numeric', month: 'long', day: 'numeric' });
};

/**
 * Parses a date string, returning a date object in the local timezone.
 *
 * > parseLocalDate('2022-10-14')
 * '2022-10-14T00:00:00.000+04:00'
 * > parseLocalDate(new Date())
 * '2021-01-14T00:00:00.000+04:00'
 */
export const parseLocalDate = (date: Date | DateTime | string) => {
  if (date instanceof DateTime) {
    date = date.toJSDate();
  }

  if (date instanceof Date) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  }

  // Strip off any potential time component
  date = date.replace(/[T ].*$/, '');
  const [year, month, day] = date.split('-').map(Number);
  return new Date(year, month - 1, day);
};

/**
 * Parses a date string, returning a Luxon DateTime object in the local timezone.
 *
 * > parseLocalDate('2022-10-14')
 * '2022-10-14T00:00:00.000+04:00'
 * > parseLocalDate(new Date())
 * '2021-01-14T00:00:00.000+04:00'
 */
export const parseLocalDateLux = (date: Date | string | null) => {
  if (!date) {
    return null;
  }
  if (date instanceof Date) {
    return DateTime.fromJSDate(date).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  }
  return DateTime.fromISO(date).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
};

/**
 * Parses a date + time string to a Date object. If no timezone is specified, it
 * is assumed to be in the local timezone.
 *
 * > parseLocalDateTime('2022-10-14', '12:13:14)
 * '2022-10-14T12:13:14.000-04:00'
 * > parseLocalDateTime('2022-10-14T12:13:14.000')
 * '2022-10-14T12:13:14.000-04:00'
 * > parseLocalDateTime('2022-10-14T12:13:14.000-04:00')
 * '2022-10-14T12:13:14.000-04:00'
 */
export const parseLocalDateTime = (date: string, time?: string) => {
  if (time === undefined) {
    const hasTimezone = date.match(/([+-]\d\d:\d\d|Z)$/);
    if (hasTimezone) {
      return new Date(date);
    }
    time = date.replace(/.*[T ]/, '');
    date = date.replace(/[T ].*$/, '');
  }
  const [year, month, day] = date.split('-').map(Number);
  const [hours, minutes, seconds] = time.split(':').map(Number);
  return new Date(year, month - 1, day, hours, minutes, seconds);
};

export const parseLocalTime = (time: string): Date => {
  const hasTimezone = time.match(/([+-]\d\d:\d\d|Z)$/);
  if (hasTimezone) {
    return new Date(time);
  }
  if (time.includes('T')) {
    time = time.replace(/.*[T ]/, '');
  }
  const [hours, minutes, seconds] = time.split(':').map(Number);
  const today = new Date();
  return new Date(today.getFullYear(), today.getMonth(), today.getDate(), hours, minutes, seconds || 0);
};

/**
 * Parses a date + time string to a Luxon DateTime object. If no timezone is
 * specified, it is assumed to be in the local timezone.
 *
 * > parseLocalDateTime('2022-10-14', '12:13:14)
 * '2022-10-14T12:13:14.000-04:00'
 * > parseLocalDateTime('2022-10-14T12:13:14.000')
 * '2022-10-14T12:13:14.000-04:00'
 * > parseLocalDateTime('2022-10-14T12:13:14.000-04:00')
 * '2022-10-14T12:13:14.000-04:00'
 */
export const parseLocalDateTimeLux = (date: string, time?: string) => {
  return DateTime.fromJSDate(parseLocalDateTime(date, time));
};

/**
 * Parses an ISO formatted date+time string, returning a Date object in local
 * time.
 * > parseIsoDateTime('2022-10-14T12:13:14.000Z')
 * Date('2022-10-14T12:13:14.000-04:00')
 */
export const parseIsoDateTime = (date: string) => {
  return DateTime.fromISO(date).toJSDate();
};

export const getTimeFromIsoDate = (isoDate: string) => parseIsoDateTime(isoDate).getTime();

export const formatAMPM = (date: Date | string | null) => {
  if (!date) {
    return '';
  }
  if (typeof date === 'string') {
    date = parseLocalTime(date);
  }
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12;
  const minutesString = minutes.toString().padStart(2, '0');
  const hoursString = hours.toString();
  return hoursString + ':' + minutesString + ' ' + ampm;
};

/**
 * Formats a time in `HH AM/PM` format.
 * > formatTimeHHAMPM('12:00:00') -> '12 AM'
 */
export const formatTimeHHAMPM = (time: string | null): string => {
  if (!time) {
    return '';
  }
  const [hours] = time.split(':').map(parseFloat);
  return `${hours % 12 || 12} ${hours >= 12 ? 'PM' : 'AM'}`;
};

export const FormatAMPMStr = (time: string | null) => {
  if (!time) {
    return '';
  }

  if (time.length < 8) {
    let hours = parseInt(time.slice(0, 1));
    const minutes = parseInt(time.slice(2, 4));
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    const minutesString = minutes.toString().padStart(2, '0');
    const hoursString = hours.toString();
    return hoursString + ':' + minutesString + ' ' + ampm;
  }
  let hours = parseInt(time.slice(0, 2));
  const minutes = parseInt(time.slice(3, 5));

  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12;
  const minutesString = minutes.toString().padStart(2, '0');
  const hoursString = hours.toString();
  return hoursString + ':' + minutesString + ' ' + ampm;
};

/**
 * Formats the time in HH:MM:SS format.
 * > formatTime(new Date())
 * '12:13:42'
 */
export const formatTime = (date: Date | DateTime | null) => {
  if (!date) {
    return null;
  }
  if (date instanceof DateTime) {
    date = date.toJSDate();
  }
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  const minutesString = minutes.toString().padStart(2, '0');
  const secondsString = seconds.toString().padStart(2, '0');
  const hoursString = hours.toString().padStart(2, '0');
  return hoursString + ':' + minutesString + ':' + secondsString;
};

export const replaceTimeWithNow = (d: Date): Date => {
  const res = new Date(d.getTime());
  const now = new Date();
  res.setHours(now.getHours());
  res.setMinutes(now.getMinutes());
  res.setSeconds(now.getSeconds());
  return res;
};

export const replaceTimeWithTimeStr = (date: Date, time: string): Date => {
  if (!date || !time) {
    return null;
  }
  const res = new Date(date.getTime());
  res.setHours(parseInt(time.slice(0, 2)));
  res.setMinutes(parseInt(time.slice(3, 5)));
  res.setSeconds(parseInt(time.slice(6, 8)));
  return res;
};

/**
 * Parses a timestamp in HH:MM:SS format returning a float
 * representing the number of hours.
 *
 * > timeToFloat('12:00:00') -> 12.00
 * > timeToFloat('12:30:00') -> 12.50
 */
const timeToFloat = (time: string): number => {
  const [hours, minutes, seconds] = time.split(':').map(parseFloat);
  return hours + minutes / 60 + seconds / 3600;
};

export type MealName = 'breakfast' | 'lunch' | 'dinner' | 'snack';

/**
 * Determine the most appropriate meal name for a given time.
 *
 * Logic:
 * - Assume people sometimes eat meals late, but rarely eat them more than 1 hour
 *   early (ex, if your breakfast is 8h and your lunch is 12h, if you eat a meal
 *   at 10h or 10:30h you'd probably consider that "breakfast" instead of "lunch",
 *   even though it's closer to lunch)
 * - So we select the meal that's no more than 1h in the future, and the meal
 *   that's "least in the past" (ex, at 14h, breakfast would be 6 hours ago but
 *   lunch is only 2 hours ago, so lunch is "least in the past")
 * - If the time between "now" and the meal is more than 4 hours, call it a snack
 *
 * For the default meal times (8h, 12h, 16h), this leads to:
 * - 5h -> 11h: breakfast
 * - 11h -> 17h: lunch
 * - 17h -> 23h: dinner
 * - 23h -> 5h: snack
 */
export const getNearestMealName = (user: PatientResponse, date?: Date): MealName => {
  const now = timeToFloat(formatTime(date || new Date()));

  const patientMealTimes: Array<[number, string]> = [
    [timeToFloat(user.meal_time_breakfast || '08:00:00'), 'breakfast'],
    [timeToFloat(user.meal_time_lunch || '12:00:00'), 'lunch'],
    [timeToFloat(user.meal_time_dinner || '18:00:00'), 'dinner'],
  ];

  const distanceToMeal: Array<[number, string]> = patientMealTimes.map(([mealTime, mealName]) => {
    return [
      mealTime - 1 < now
        ? Math.abs(mealTime - now)
        : 999,
      mealName,
    ];
  });

  distanceToMeal.sort((a, b) => a[0] - b[0]);

  const [mealTime, mealName] = distanceToMeal[0];
  if (mealTime > 4) {
    if (now > 5.0 && now < patientMealTimes[0][0]) {
      return 'breakfast';
    }
    return 'snack';
  }
  return mealName as MealName;
};

/**
 * Formats a date+time in 'YYYY-MM-DD HH:MM:SS' format.
 */
export const formatDateTime = (d: Date | DateTime | null) => {
  if (!d) {
    return '';
  }
  return formatDate(d) + ' ' + formatTime(d);
};

/**
 * Formats a date+time in 'Jan. 3, 2023, 10:56 AM' format.
 */
export const formatDateTimeHuman = (locale: string, d: Date | null) => {
  if (!d) {
    return '';
  }
  return formatDateShortMonth(locale, d) + ', ' + formatAMPM(d);
};

/**
 * Returns a relative time formatter which will automatically update.
 *
 * Example:
 *   const timeFormatter = useRelativeTimeFormatter()
 *   return <Text>{timeFormatter.short(new Date())}</Text>
 *
 * Will render:
 *  - "Just now" if the date is within 1 minute of now
 *  - "2 minutes ago" if the date is within 2 minutes of now
 *  - "1PM" if the date is on the same day, but more than 30 minutes ago
 *  - "Tuesday, 2PM" if the date is within the last 7 days
 *  - "Jul 4, 2PM" if the date is more than 7 days ago
 *
 * Returns '–' if the date is `null`.
 *
 * Luxon is used internally.
 */
export const useRelativeTimeFormatter = () => {
  const { t } = useTranslation();
  const [now, setNow] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setNow(new Date());
    }, 60 * 1000);
    return () => clearInterval(interval);
  }, []);

  const formatter = useMemo(() => {
    return {
      short: (date: Date) => {
        if (!date || !(date instanceof Date)) {
          return '–';
        }
        const d = DateTime.fromJSDate(date);
        const n = DateTime.fromJSDate(now);
        const diff = n.diff(d, 'minutes').minutes;
        if (diff < 1) {
          return t('Just now');
        } else if (diff < 2) {
          return t('1 minute ago');
        } else if (diff < 30) {
          return diff.toFixed(0) + ' ' + t('minutes ago');
        } else if (diff < 60 * 24) {
          return d.toFormat('h a');
        } else if (diff < 60 * 24 * 7) {
          return d.toFormat('ccc, h a');
        }
        return d.toFormat('LLL dd, h a');
      },
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [now]);

  return formatter;
};

/*
 * @deprecated use `parseLocalDateTime` instead.

 * Converts a pair of `date` and `time` strings into a `Date` object, or `null`
 * if parsing fails.
 */
export const LEGACY_dateTimeToDate = (date: string, time: string): Date => {
  if (!date || !time) {
    return null;
  }

  const res = DateTime.fromISO(date + 'T' + time).toJSDate();
  if (isNaN(res.getTime())) {
    return null;
  }

  return res;
};
