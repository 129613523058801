import React from 'react';
import { VictoryLabelProps } from 'victory';
import { VictoryChart } from 'victory';
import { VictoryChartProps } from 'victory';
import { RxErrorBoundary } from './RxErrorBoundary';
/**
 * Wrapper around VictoryChart that adds an error boundary and requires the `width` prop.
 *
 * Note: `width` is required because VictoryChart isn't natively responsive, and
 * calculating the width using `onLayout` leads to a blink of the chart being
 * rendered at the wrong width.
 */
export const RxVictoryChart = (props: VictoryChartProps) => {
  return (
    <RxErrorBoundary>
      <VictoryChart {...props} />
    </RxErrorBoundary>
  );
};

export type VictoryInjectedProps = {
  domain: {
    x: [number, number],
    y: [number, number],
  },
  scale: any,
  ticks: any[],
} & VictoryLabelProps;

/**
 * Victory components are injected with props that are not necessarily required
 * when the component is used.
 *
 * For example, RangeClipPaths is a Victory component that is injected with
 * `domain` and `scale` props.  However, the component only requires
 * `glucoseUnits` to determine the bounds of the clip paths.
 *
 * This wrapper ensures that the Victory props are available within the
 * component, but not required by callers.
 */
export function victoryComponent<T extends VictoryInjectedProps>(component: React.ComponentType<T>) {
  return component as React.ComponentType<
    & Omit<T, keyof VictoryInjectedProps>
    & Partial<VictoryInjectedProps>
  >;
}
