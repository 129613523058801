import _i18n, { TFunction } from 'i18next';
import React, { useEffect } from 'react';
import { initReactI18next, Trans as TransReal, useTranslation as useTranslationReal } from 'react-i18next';

// Polyfill 'Intl' for Android support
import '@formatjs/intl-getcanonicallocales/polyfill';
import '@formatjs/intl-locale/polyfill';
import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/locale-data/en';
import '@formatjs/intl-pluralrules/locale-data/fr';
import '@formatjs/intl-numberformat/polyfill';
import '@formatjs/intl-numberformat/locale-data/en';
import '@formatjs/intl-numberformat/locale-data/fr';
import '@formatjs/intl-datetimeformat/polyfill';
import '@formatjs/intl-datetimeformat/locale-data/en';
import '@formatjs/intl-datetimeformat/locale-data/fr';
import '@formatjs/intl-datetimeformat/add-all-tz';
import moment from 'moment';
import { useStore } from './context';
import 'moment/locale/fr';

export const i18n = _i18n;

// Type defintion for the `t` function:
//   const getSomeMessage = (t: TFunc) => {
//     reutrn t('Some message')
//   }
//   const MyComponent = () => {
//     const { t } = useTranslation()
//     return <Text>{getSomeMessage(t)}</Text>
//   }
export type TranslationsForFieldValue = object | null | undefined;

export type TFunc = TFunction<'translation', any>;
export type TAttrFunc = <T, N extends (string & keyof T)>(
  obj:
    & T
    & Partial<Record<`${N}_translations`, TranslationsForFieldValue>>
    & { [K in N]?: string },
  field: N,
  count?: number,
) => string;

export const Trans = (
  props: {
    i18nKey?: string,
    count?: number,
    children?: React.ReactNode,
  },
) => {
  const { t } = useTranslation();
  const { i18nKey, count } = props;

  return <TransReal t={t} count={count} i18nKey={i18nKey}>{props.children}</TransReal>;
};

export const useTranslation = () => {
  const res = useTranslationReal();

  /**
   * Translates an attribute of an object, looking up the translation in the
   * object's `${field}_translations` attribute:
   *
   *  const obj = { name: "apple", name_translations: { "fr:one": "pomme", "fr:other": "pommes" } }
   *  tAttr(obj, "name") // => "pomme"
   *  tAttr(obj, "name", 1) // => "pomme"
   *  tAttr(obj, "name", 2) // => "pommes"
   */
  const tAttr: TAttrFunc = (obj, field, count) => {
    // This may not be necessary - not sure if there will be cases where this will be called with a null object
    if (!obj) {
      return;
    }

    if (field == 'food_name' && obj['food_name_alias']) {
      // Food name aliases are used for meal items with a generic base food, but
      // a patient-specific name (for example, a base food of "apple pie", and
      // an alias of "mom's apple pie").
      // Implementing the "show food_name_alias instead of food_name" logic here
      // is a bit of a hack, but one that seems to work  ¯\_(ツ)_/¯
      return tAttr(obj as any, 'food_name_alias', count);
    }

    // eslint-disable-next-line i18next/no-literal-string
    const translations = obj[`${field}_translations`];
    if (!translations) {
      return obj[field];
    }

    /* eslint-disable i18next/no-literal-string */
    const pluralization = count === undefined || count == 1
      ? 'one'
      : 'other';
    /* eslint-enable i18next/no-literal-string */

    const lang = res.i18n.language;

    return (
      // eslint-disable-next-line i18next/no-literal-string
      translations[`${lang}:${pluralization}`]
      // eslint-disable-next-line i18next/no-literal-string
      || translations[`${lang}:other`]
      // eslint-disable-next-line i18next/no-literal-string
      || translations[`${lang}:one`]
      || translations[lang]
      || obj[field]
    );
  };

  return {
    ...res,
    changeLanguage: (locale: string) => {
      return res.i18n.changeLanguage(locale);
    },
    tAttr,
  };
};

/**
 * Changes the app's language based on the user's preferred language.
 */
export const UserDefaultLanguageHandler = () => {
  const { i18n } = useTranslation();
  const { clinician } = useStore();
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const urlLang = urlParams.get('lang');
    // eslint-disable-next-line i18next/no-literal-string
    const locale = urlLang || clinician.locale || 'en';
    i18n.changeLanguage(locale);
    moment.locale(locale);
    console.log(`Changed language to ${locale}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clinician.locale]);

  return null;
};

// eslint-disable-next-line i18next/no-literal-string
const defaultNamespace = 'clinician';

_i18n
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    resources: {
      en: {
        translation: require(`../public/locales/en/${defaultNamespace}.json`),
      },
      fr: {
        translation: require(`../public/locales/fr/${defaultNamespace}.json`),
      },
    },

    detection: {
      // order and from where user language should be detected
      order: ['querystring', 'cookie', 'localStorage'],

      // keys or params to lookup language from
      lookupQuerystring: 'lang',
      lookupCookie: 'i18next',
      lookupLocalStorage: 'i18nextLng',

      // cache user language on
      caches: ['localStorage', 'cookie'],
      excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

      // optional expire and domain for set cookie
      cookieMinutes: 60 * 60 * 24 * 30,

      // only detect languages that are in the whitelist
      checkWhitelist: true,
    },

    interpolation: {},

    returnEmptyString: false,

    // disable key separator e.g. `t('key.subkey')`
    keySeparator: false,

    nsSeparator: '|',
  });
