import DateFnsUtils from '@date-io/moment';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import classnames from 'classnames';
import { TFunction } from 'i18next';
import _ from 'lodash';
import { DateTime } from 'luxon';
import moment, { Moment } from 'moment';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { create } from 'zustand';
import { patientApi, reportingApi } from '../api';
import {
  MealResponse,
  PatientActivityFeedRowExerciseLog,
  PatientActivityFeedRowMealLog,
  PatientActivityFeedRowMedicationLog,
  PatientEmotionalHealthResponse,
  PatientEmotionResponse,
  PatientMindfulnessResponse,
  PatientNutritionDetailsTableRow,
  PatientSleepDataResponse,
  PatientStepsDataResponse,
  PatientStressResponse,
} from '../api/generated/models';
import { useCgmData, useProcessedCgmData } from '../components/cgm/CGMDataService';
import { CGMGlucoseChart, CGMReportMarkerPoint } from '../components/cgm/CGMGlucoseChart';
import { LoadingSpinner } from '../components/loadingSpinner';
import {
  EmotionCard,
  ExerciseCard,
  getNutrientLabelDefs,
  MealCard,
  mealItemIsVerifying,
  MedicationCard,
} from '../components/mealCard';
import { MindfulEatingSurveyIcon } from '../components/patientSurvey';
import { AutoScrollHere, ShowMealV2, useRecentlyModifiedMeal } from '../components/showMealV2';
import { WaterLog } from '../components/waterLog';
import { useCurrentPatientData, useStore } from '../context';
import { TFunc, Trans, useTranslation } from '../i18n';
import EmotionImage from '../theme/icons/emotion.svg';
import ExerciseImage from '../theme/icons/exercise.svg';
import InsulinImage from '../theme/icons/insulin.svg';
import MealImage from '../theme/icons/meal.svg';
import { formatDateLongMonth, MealName, parseLocalDateTimeLux } from '../utils/formatDate';
import { formatNumber, titleCase } from '../utils/formatters';
import './patient-logV2.scss';
import { ANALYTICS_EVENTS, track } from '../analytics';

type ActivityFeedItemType<TypeName, TypeDef = null> = {
  id: string,
  timestamp: DateTime,
  type: TypeName,
  data: TypeDef,
};

export type ActivityFeedItemMealLog = ActivityFeedItemType<'meal-log', PatientActivityFeedRowMealLog['data']>;
export type ActivityFeedItemExerciseLog = ActivityFeedItemType<
  'exercise-log',
  PatientActivityFeedRowExerciseLog['data']
>;
export type ActivityFeedItemMedicationLog = ActivityFeedItemType<
  'medication-log',
  PatientActivityFeedRowMedicationLog['data']
>;

export type ActivityFeedItemCGMSummary = ActivityFeedItemType<'cgm-summary'>;
export type ActivityFeedItemWaterLog = ActivityFeedItemType<'water-log'>;
export type ActivityFeedItemMoodButton = ActivityFeedItemType<'mood-button'>;
export type ActivityFeedItemMissingMeal = ActivityFeedItemType<'missing-meal', {
  meal_name: MealName,
}>;
export type ActivityFeedItemEmotionLog = ActivityFeedItemType<'emotion-log', PatientEmotionResponse>;
export type ActivityFeedItemEmotionalHealthLog = ActivityFeedItemType<
  'emotional-health-log',
  PatientEmotionalHealthResponse
>;
export type ActivityFeedItemMindfulnessLog = ActivityFeedItemType<'mindfulness-log', PatientMindfulnessResponse>;
export type ActivityFeedItemSleepLog = ActivityFeedItemType<'sleep-log', PatientSleepDataResponse>;
export type ActivityFeedItemStressLog = ActivityFeedItemType<'stress-log', PatientStressResponse>;
export type ActivityFeedItemStepsLog = ActivityFeedItemType<'steps-log', PatientStepsDataResponse>;

export type ActivityFeedItem =
  | ActivityFeedItemMealLog
  | ActivityFeedItemExerciseLog
  | ActivityFeedItemMedicationLog
  | ActivityFeedItemCGMSummary
  | ActivityFeedItemWaterLog
  | ActivityFeedItemMoodButton
  | ActivityFeedItemMissingMeal
  | ActivityFeedItemEmotionLog
  | ActivityFeedItemEmotionalHealthLog
  | ActivityFeedItemMindfulnessLog
  | ActivityFeedItemSleepLog
  | ActivityFeedItemStressLog
  | ActivityFeedItemStepsLog;

export type ActivityFeedSection = {
  id: string,
  date: DateTime,
  data: ActivityFeedItem[],
};

export const FILTER_OPTIONS = (t: TFunction) =>
  ({
    mealType: {
      label: t('Meal type'),
      options: [
        {
          'value': 'breakfast',
          'label': t('Breakfast'),
        },
        {
          'value': 'lunch',
          'label': t('Lunch'),
        },
        {
          'value': 'dinner',
          'label': t('Dinner'),
        },
        {
          'value': 'snack',
          'label': t('Snack'),
        },
      ],
    },
    nutrition: {
      label: t('Nutrition'),
      options: [
        // negative nutrition values commented out for now
        {
          'value': 'healthy_fat',
          'label': t('Healthy fat'),
        },
        // {
        // 	"value": "unhealthy_fat",
        // 	"label": "Unhealthy fat"
        // },
        {
          'value': 'plant_based',
          'label': t('Plant-based protein'),
        },
        {
          'value': 'ultraprocessed',
          'label': t('Processed foods'),
        },
        {
          'value': 'omega_three',
          'label': t('Omega-3'),
        },
        {
          'value': 'refined_grains',
          'label': t('Refined grains'),
        },
        {
          'value': 'whole',
          'label': t('Whole grains'),
        },
        {
          'value': 'high_fiber',
          'label': t('Fibre'),
        },
        {
          'value': 'sugary_beverage',
          'label': t('Sugary drinks'),
        },
        {
          'value': 'redmeat',
          'label': t('Red meat'),
        },
        {
          'value': 'nuts_seeds',
          'label': t('Nuts and seeds'),
        },
        {
          'value': 'legume',
          'label': t('Legumes'),
        },
        {
          'value': 'vegetable',
          'label': t('Vegetables'),
        },
        {
          'value': 'fruit',
          'label': t('Fruits'),
        },
      ],
    },
    glucose: {
      // hidden: !user?.flags?.patient_show_dexcom_oauth,
      label: t('Glucose'),
      options: [
        {
          'value': 'glucose_response_low',
          'label': t('Low'),
          'cardLabel': t('Low glucose'),
        },
        {
          'value': 'glucose_response_high',
          'label': t('High'),
          'cardLabel': t('High glucose'),
        },
        {
          'value': 'glucose_response_in_range',
          'label': t('In-range'),
          'cardLabel': t('In-range glucose'),
        },
        {
          'value': 'glucose_response_unknown',
          'label': t('Unknown/missing'),
          'cardLabel': t('Unknown/missing glucose'),
        },
      ],
    },
    logType: {
      label: t('Log Type'),
      options: [
        {
          'value': 'exercise-log',
          'label': t('Exercise'),
          'cardLabel': t('Exercise log'),
        },
        {
          'value': 'medication-log',
          'label': t('Medication'),
          'cardLabel': t('Medication log'),
        },
        {
          'value': 'meal-log',
          'label': t('Meal'),
          'cardLabel': t('Meal log'),
        },
        {
          'value': 'has_mindful_eating_survey',
          'label': t('Mindful eating'),
          'cardLabel': t('Mindful eating'),
        },
      ],
    },
  }) as const;

type ApiTableFilterVal = string | number | boolean | null | undefined;

type ApiTableFilter<T = ApiTableFilterVal> =
  | T
  | Partial<{
    lt: T,
    lte: T,
    gt: T,
    gte: T,
    eq: T,
    neq: T,
  }>;

export type ActivityFeedFilterOptions = Partial<ReturnType<typeof FILTER_OPTIONS>>;

type ActivityPage = {
  activities: ActivityFeedItem[],
  earliestDay: DateTime,
  latestDay: DateTime,
  pagination: {
    total: number,
    hasNext: boolean,
    nextCursor: DateTime,
  },
};

export const useMealCache = create<Record<string, MealResponse>>(() => ({}));

export const usePatientActivityFeed = (opts: {
  patientId: number,
  filterOptions?: SelectedOptionsType,
  filter?: Partial<{ timestamp: ApiTableFilter }>,
  activeDate?: DateTime | null,
}) => {
  const { filterOptions, filter, activeDate, patientId } = opts;

  const mealCache = useMealCache();

  const query = useInfiniteQuery<ActivityPage>({
    queryKey: ['meal-timeline', patientId, filterOptions, filter, activeDate],
    getNextPageParam: (lastPage, pages) => {
      if (!lastPage.pagination.hasNext) {
        return;
      }
      return lastPage.pagination;
    },
    queryFn: async (context): Promise<ActivityPage> => {
      const pageParam: ActivityPage['pagination'] = context.pageParam;
      const before: DateTime | boolean = pageParam
        ? pageParam.hasNext && pageParam.nextCursor
        : activeDate
        ? activeDate
        : DateTime.now();

      if (!before) {
        return {
          activities: [],
          earliestDay: null,
          latestDay: null,
          pagination: {
            total: 0,
            nextCursor: null,
            hasNext: false,
          },
        };
      }

      const getFilterValues = (filterType: keyof ActivityFeedFilterOptions, prependCc?: string) => {
        return Object.entries(filterOptions?.[filterType] || {})
          .filter(([_, value]) => value)
          .map(([key, _]) => prependCc ? prependCc + key : key);
      };

      const mealFilter: Record<string, boolean | Record<'in', MealName[]>> = {};
      const logTypeFilter: Record<string, ActivityFeedItem['type'][]> = {};

      getFilterValues('logType').map(type => {
        if (type == 'has_mindful_eating_survey') {
          mealFilter[type] = true;
        } else {
          logTypeFilter['or'] = logTypeFilter['or'] || [];
          logTypeFilter['or'].push(type as ActivityFeedItem['type']);
        }
      });
      getFilterValues('mealType').map(meal => {
        mealFilter['meal_name'] = mealFilter['meal_name'] || { in: [] };
        mealFilter['meal_name']['in'].push(meal);
      });
      getFilterValues('nutrition', 'cc_').map(flag => mealFilter[flag] = true);
      getFilterValues('glucose').map(flag => mealFilter[flag] = true);

      const res = await patientApi.appApiPatientActivityFeedGetPatientActivityFeed({
        patient_id: patientId,
        filter: JSON.stringify({
          'meal-log': mealFilter,
          'type': logTypeFilter,
          ...(filter || {}),
        }),
        before: before.plus({ days: 1 }).toISODate(),
        limit: 100,
      });

      const newCache = { ...mealCache };
      res.data.rows.forEach((row) => {
        if (row.type == 'meal-log') {
          delete newCache[row.data.id];
        }
      });
      useMealCache.setState(newCache);

      const wrappedActivities = res.data.rows.map((item) => ({
        ...item,
        timestamp: parseLocalDateTimeLux(item.timestamp),
      }));

      /*
      if (config.IS_LOCAL || config.IS_DEV) {
        const firstDate = before;
        wrappedActivities.push({
          id: `debug-exercise-${firstDate.toMillis()}`,
          type: 'exercise-log',
          timestamp: firstDate.set({ hour: 13, minute: 0, second: 0, millisecond: 0 }),
          data: {
            id: firstDate.toMillis(),
            duration_min: 30,
            exercise_date: firstDate.toISODate(),
            exercise_time: '13:00:00',
            exercise_type: 'aerobic training',
            intensity: 'medium',
          },
        });
        wrappedActivities.push({
          id: `debug-medication-${firstDate.toMillis() + 1}`,
          type: 'medication-log',
          timestamp: firstDate.set({ hour: 11, minute: 45, second: 0, millisecond: 0 }),
          data: {
            id: firstDate.toMillis() + 1,
            medication_date: firstDate.toISODate(),
            medication_time: '11:45:00',
            name: 'insulin',
            quantity: 3,
            unit: 'units',
          },
        });
      }
      */

      wrappedActivities.sort((a, b) => b.timestamp.toMillis() - a.timestamp.toMillis());
      const earliestDay = before;
      const latestDay = wrappedActivities[wrappedActivities.length - 1]?.timestamp.endOf('day');
      return {
        activities: wrappedActivities,
        earliestDay,
        latestDay,
        pagination: {
          total: res.data.rows.length,
          nextCursor: latestDay?.minus({ days: 1 }),
          hasNext: !!res.data.has_more,
        },
      };
    },
  });

  const feedEntries = useMemo(() => {
    if (!query.data) {
      return [];
    }

    const latestDay = query.data.pages[0].earliestDay;
    // const earliestDay = query.data.pages[query.data.pages.length - 1].latestDay
    // console.log("RANGE:", earliestDay.toISO(), latestDay.toISO())

    const sectionsObj = {} as Record<
      string, // date string
      ActivityFeedSection
    >;
    const getOrInsertSection = (day: DateTime): ActivityFeedSection => {
      if (!sectionsObj[day.toISODate()]) {
        sectionsObj[day.toISODate()] = {
          id: `${day.toISODate()}-${Math.random().toString()}`,
          date: day,
          data: [],
        };
      }
      return sectionsObj[day.toISODate()];
    };

    // go from latestDay to earliestDay
    for (let offset = 0; offset < 5; offset++) {
      // Insert empty sections for the first 5 days
      getOrInsertSection(latestDay.minus({ days: offset }));
    }

    const seenMealIds = {} as Record<string, boolean>;

    // add activities to days
    query.data.pages.forEach((page) => {
      page.activities.forEach((activity) => {
        if (activity.type != 'meal-log') {
          const section = getOrInsertSection(activity.timestamp);
          section.data.push(activity);
          return;
        }

        const cached = mealCache[activity.data.id];
        if (cached && (cached.is_deleted)) {
          return;
        }

        const activityToAdd = !cached
          ? activity
          : { ...activity, data: cached };

        seenMealIds[activityToAdd.data.id] = true;
        if (activityToAdd !== activity) {
          activityToAdd.timestamp = parseLocalDateTimeLux(activityToAdd.data.meal_date, activityToAdd.data.meal_time);
        }

        const section = getOrInsertSection(activity.timestamp);
        section.data.push(activityToAdd);
      });
    });

    // add missing meals
    Object.values(mealCache).forEach((meal) => {
      if (seenMealIds[meal.id] || meal.is_deleted || (patientId != meal.patient_id)) {
        return;
      }

      const activity: ActivityFeedItemMealLog = {
        id: `meal-log:${meal.id}`,
        type: 'meal-log',
        timestamp: parseLocalDateTimeLux(meal.meal_date, meal.meal_time),
        data: meal,
      };
      const section = getOrInsertSection(activity.timestamp);
      section.data.push(activity);
    });

    const sections = Object.values(sectionsObj);
    // sections.forEach((section) => {
    //   (['breakfast', 'lunch', 'dinner', 'snack'] as const).forEach((meal_name) => {
    //     insertMissingMeals(section, meal_name, user);
    //   });
    // });

    sections.forEach((section) => {
      section.data.sort((a, b) => {
        return b.timestamp.toMillis() - a.timestamp.toMillis();
      });
    });

    return sections.filter(s => s.data.length > 0);
  }, [query.data, mealCache, patientId]);

  const filteredFeedEntries = useMemo(() => {
    return feedEntries.filter((section) => section.data.length > 0);
  }, [feedEntries]);

  return {
    query,
    feedEntries,
    filteredFeedEntries,
  };
};

export const activityFeedItemsToCgmSummaryChartPoints = (
  t: TFunc,
  activityData: ActivityFeedItem[],
): CGMReportMarkerPoint[] => {
  if (!activityData?.length) {
    return [];
  }
  const defs = getActivityFeedDefs(t);

  return activityData.map((item, i) => {
    const def = defs[item.type];
    if (!def) {
      return null;
    }

    const res: CGMReportMarkerPoint = {
      icon: def.iconImage,
      color: def.color,
      xTimestampMS: item.timestamp.toJSDate(),
    };
    return res;
  }).filter(Boolean) as CGMReportMarkerPoint[];
};

const CgmSummaryItem = (props: {
  timestamp: DateTime,
  activityData: ActivityFeedItem[],
}) => {
  const { t } = useTranslation();
  const { patient } = useStore();
  const date = props.timestamp;
  const viewDates = {
    patientId: patient.patient_id,
    timeSinceLocal: date.startOf('day').toJSDate(),
    timeUntilLocal: date.endOf('day').toJSDate(),
  };

  // const cgmRefetch = useCgmRefetch(); TODO
  const cgm = useCgmData(viewDates);
  const cgmData = useProcessedCgmData(cgm.glucoseData);

  const width = 240;

  const result = useMemo(() => {
    if (!cgmData || cgmData.length === 0) {
      return null;
    }

    const markers = activityFeedItemsToCgmSummaryChartPoints(t, props.activityData);
    return (
      <CGMGlucoseChart
        data={cgmData}
        markers={markers}
        height={110}
        width={width}
        showValueAxis={true}
        showTimeAxis={true}
        xTickFrequencyHours={3}
        xTickFirstOffsetHours={3}
        maxXTicks={12}
        xDomainMS={[viewDates.timeSinceLocal.getTime(), viewDates.timeUntilLocal.getTime()]}
        showTargetRange={true}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    cgmData,
    props.activityData,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    viewDates.timeSinceLocal.toISOString(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    viewDates.timeUntilLocal.toISOString(),
    width,
  ]);

  return result;
};

const MacroCardLargeStat = (props: { value: string | null, label: string, unit: string, color?: string }) => {
  return (
    <div>
      <span className="text-uppercase" style={{ fontSize: '14px', color: props.color }}>
        <Trans>{props.label}</Trans>
      </span>
      <h5 className="mb-0" style={{ color: props.color }}>
        {typeof props.value === 'string' ? props.value + props.unit : '-'}
      </h5>
    </div>
  );
};

export const MACRO_NUTRIENT_QUERY_KEY = 'patient-nutritional-report';
export const MacroNutrientSummaryCard = (props: {
  summaryDate: DateTime,
  mealId?: number,
  meal?: ActivityFeedItem[] | MealResponse,
}) => {
  const { summaryDate } = props;
  const { patient } = useStore();
  const { t, i18n } = useTranslation();

  const nutrientQuery = useQuery([MACRO_NUTRIENT_QUERY_KEY, summaryDate, props.mealId], async () => {
    const res = await reportingApi.appApiPatientReportsApiGetPatientNutritionDetailsTable({
      patient_id: patient.patient_id,
      filter: JSON.stringify({
        meal_date: summaryDate,
        meal_id: props.mealId || undefined,
      }),
    });
    return res.data;
  }, {
    refetchOnMount: false,
    staleTime: 1000 * 60,
  });

  const nutrientData = nutrientQuery.data?.rows?.[0];
  const nutVal = (...nutrients: (keyof PatientNutritionDetailsTableRow)[]) => {
    const val = _.sum(nutrients.map(nutrient => +nutrientData?.[nutrient]));
    return formatNumber(i18n.language, val, 0);
  };

  const nutrients: (keyof PatientNutritionDetailsTableRow)[] = [
    'protein_g',
    'fat_g',
    'fiber_g',
    'energy_kcal',
    'carbohydrate_g',
    'netcarb_g',
  ];
  const labelDefs = getNutrientLabelDefs(t);
  // const carbLabel = patientFlags.flags.patient_show_total_carbs ? labelDefs.carbohydrate_g : labelDefs.netcarb_g;
  // const carbField = patientFlags.flags.patient_show_total_carbs ? 'carbohydrate_g_sum' : 'netcarb_g_sum';

  const mealHasOverrides = props.meal && (Array.isArray(props.meal)
    ? props.meal?.some(item =>
      item.type == 'meal-log'
      && (item.data.meal_items?.some(mi => mi.custom_addons?.some(ca => ca.nutrient_overrides?.aa_patient))
        || item.data.meal_items?.some(mi => mi.nutrient_overrides?.aa_patient))
    )
    : props.meal?.meal_items?.some(mi => mi.custom_addons?.some(ca => ca.nutrient_overrides?.aa_patient))
      || props.meal?.meal_items?.some(mi => mi.nutrient_overrides?.aa_patient));

  return (
    <div>
      <div className="d-flex flex-wrap justify-content-between">
        {nutrients.map(nutrient => {
          // eslint-disable-next-line i18next/no-literal-string
          const val = nutVal(`${nutrient}_sum` as keyof PatientNutritionDetailsTableRow);
          return (
            <MacroCardLargeStat
              key={nutrient}
              value={val}
              label={labelDefs[nutrient].labelTr}
              unit={labelDefs[nutrient].unitSuffix}
              color={labelDefs[nutrient].colorize(val)}
            />
          );
        })}
      </div>
      {mealHasOverrides && (
        <div style={{ display: 'flex', gap: '8px', fontStyle: 'italic', marginTop: '10px', color: '#666666' }}>
          <div style={{ fontWeight: 'bold' }}>
            <Trans>!</Trans>
          </div>
          <Trans>The patient has customized these macronutrient values.</Trans>
        </div>
      )}
    </div>
  );
};

type ActivityFeedTypeDef = {
  titleTr: string,
  iconImage: string,
  color: string,
};

export const getActivityFeedDefs = (t: TFunction): Record<ActivityFeedItem['type'], ActivityFeedTypeDef | null> => ({
  'meal-log': {
    iconImage: MealImage,
    titleTr: t('Meal'),
    color: '#36C2B4',
  },
  'exercise-log': {
    iconImage: ExerciseImage,
    titleTr: t('Exercise'),
    color: '#ff9161',
  },
  'medication-log': {
    iconImage: InsulinImage,
    titleTr: t('Medication'),
    color: '#878ef8',
  },
  'emotion-log': {
    iconImage: EmotionImage,
    titleTr: t('Emotion'),
    color: '#FFDC00',
  },
  'cgm-summary': null,
  'water-log': null,
  'mood-button': null,
  'missing-meal': null,
  'emotional-health-log': null,
  'mindfulness-log': null,
  'sleep-log': null,
  'stress-log': null,
  'steps-log': null,
});

type PatientActivityLogActivityGroup = {
  id: string,
  groupType: string,
  def: ActivityFeedTypeDef,
  items: ActivityFeedItem[],
};

const getNewOptions = (
  searchOptions: SelectedOptionsType,
  optionCategory: keyof ActivityFeedFilterOptions,
  key: FilterOptionsType<typeof optionCategory>,
) => {
  const toggleMealLogTrue = optionCategory !== 'logType' || key === 'has_mindful_eating_survey';
  const newOptions = {
    ...searchOptions,
    logType: {
      ...searchOptions['logType'],
      'meal-log': toggleMealLogTrue || searchOptions['logType']?.['meal-log'],
    },
  };
  newOptions[optionCategory] = { ...newOptions[optionCategory], [key]: !newOptions[optionCategory]?.[key] };
  if (key === 'meal-log') {
    return newOptions.logType['meal-log']
      ? newOptions
      : { logType: { ...newOptions.logType, has_mindful_eating_survey: false } };
  }

  if (newOptions[optionCategory][key] === false) {
    delete newOptions[optionCategory][key];
  }
  if (!Object.keys(newOptions[optionCategory]).length) {
    delete newOptions[optionCategory];
  }
  if (
    newOptions.logType['meal-log'] && Object.keys(newOptions.logType).length === 1
    && Object.keys(newOptions).length === 1
  ) {
    delete newOptions.logType['meal-log'];
  }
  return newOptions;
};

const PatientActivityLogDayView = (props: {
  section: ActivityFeedSection,
  openAddMealPanel: () => void,
  onClickItem: (item: ActivityFeedItem) => void,
}) => {
  const { patient, clinician } = useStore();
  const { t, i18n } = useTranslation();
  const meal = props.section;
  const patientFlags = useCurrentPatientData();
  const recentlyModifiedMeal = useRecentlyModifiedMeal().recentlyModifiedMeal;

  const groupedEntries: PatientActivityLogActivityGroup[] = React.useMemo(() => {
    const res: PatientActivityLogActivityGroup[] = [];
    const typeDefs = getActivityFeedDefs(t);

    _.sortBy(props.section.data, 'timestamp').forEach((item) => {
      const def = typeDefs[item.type];
      if (!def) {
        return;
      }
      const lastGroup = res[res.length - 1];
      // eslint-disable-next-line i18next/no-literal-string
      const groupType = item.type == 'meal-log' ? `meal-log:${item.data.meal_name}` : item.type;
      if (lastGroup?.groupType == groupType) {
        lastGroup.items.push(item);
      } else {
        res.push({
          id: `${groupType}:${item.id}`,
          groupType: groupType,
          def: {
            ...def,
            titleTr: item.type == 'meal-log' ? titleCase(t(item.data.meal_name)) : def.titleTr,
          },
          items: [item],
        });
      }
    });

    return res;
  }, [props.section, t]);

  return (
    <div className="timeline-day">
      <div className="timeline-header">
        <Row>
          <Col className="timeline-item-title d-flex align-items-center">
            <div className="d-flex date-wrapper align-items-center">
              <h4 className="m-0">{formatDateLongMonth(i18n.language, meal.date)}</h4>
              {/* eslint-disable-next-line i18next/no-literal-string*/}
              <span>&bull;</span>
              <h5 className="text-muted m-0">
                {meal.date?.setLocale(i18n.language).weekdayLong}
              </h5>
              {/* eslint-disable-next-line i18next/no-literal-string */}
              <span>&bull;</span>
            </div>
            <div className="d-none d-md-block">
              <WaterLog date={meal.date} patientId={patient.patient_id} />
            </div>
          </Col>
        </Row>
      </div>
      <div className="timeline-content">
        <Row>
          <Col xs={4}>
            <div className="day-summary">
              {patientFlags.flags.patient_show_dexcom_oauth && (
                <>
                  <h5>
                    <Trans>Glucose profile</Trans>
                  </h5>
                  <div className="CGMgraphContainer">
                    <CgmSummaryItem
                      timestamp={props.section.date}
                      activityData={props.section.data}
                    />
                  </div>
                </>
              )}
              <MacroNutrientSummaryCard summaryDate={meal.date} meal={meal.data} />
            </div>
          </Col>
          <Col xs={8}>
            <div className="meal-container w-100 h-100">
              {groupedEntries.map((group) => (
                <div key={group.id}>
                  {recentlyModifiedMeal
                    && group.items.find(item => item.type == 'meal-log' && item.data == recentlyModifiedMeal)
                    && <AutoScrollHere />}

                  <div className="mx-3 my-2 d-flex align-items-center">
                    <div
                      className="meal-card-icon d-inline-flex align-items-center justify-content-center"
                      style={{ backgroundColor: group.def.color }}
                    >
                      <img src={group.def.iconImage} width={20} />
                    </div>
                    <h5 className="d-inline-block m-0 px-2">
                      {group.def.titleTr}
                    </h5>
                  </div>
                  <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {group.items.map((item, itemIdx) => {
                      const isVerifyingMeal = item.type == 'meal-log'
                        && item.data.meal_items?.some(mi => mealItemIsVerifying(mi));
                      return (
                        <React.Fragment key={item.id + itemIdx}>
                          {item.type == 'meal-log' && item.data.meal_items
                            ? (
                              <div
                                className="d-inline-block m-1"
                                style={{ cursor: isVerifyingMeal ? 'default' : 'pointer' }}
                                onClick={() => {
                                  if (isVerifyingMeal) {
                                    return;
                                  }
                                  props.onClickItem(item);
                                }}
                              >
                                <MealCard
                                  key={item.id}
                                  meal={item.data}
                                />
                                <MindfulEatingSurveyIcon mealId={item.data.id} />
                                {/* TODO: other types */}
                              </div>
                            )
                            : item.type == 'exercise-log'
                            ? <ExerciseCard exercise={item.data} />
                            : item.type == 'medication-log'
                            ? <MedicationCard medication={item.data} />
                            : item.type == 'emotion-log'
                            ? <EmotionCard emotion={item.data} />
                            : null}
                        </React.Fragment>
                      );
                    })}
                  </div>
                </div>
              ))}
              {!groupedEntries.length && (
                <div className="w-100 h-100 d-flex flex-column align-items-center justify-content-center">
                  <h4 className="text-muted">
                    <Trans>No meals logged</Trans>
                  </h4>
                  {clinician.flags.clinician_allow_meal_edit && (
                    <span
                      className="addMealBtn"
                      onClick={props.openAddMealPanel}
                      style={{ cursor: 'pointer' }}
                    >
                      <Trans>Add a meal</Trans> <i className="fas fa-plus" />
                    </span>
                  )}
                </div>
              )}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export type FilterOptionsType<T extends keyof ActivityFeedFilterOptions> =
  ActivityFeedFilterOptions[T]['options'][number]['value'];
export type SelectedOptionsType =
  | { logType?: Partial<Record<FilterOptionsType<'logType'>, boolean>> }
  | { mealType?: Partial<Record<FilterOptionsType<'mealType'>, boolean>> }
  | { nutrition?: Partial<Record<FilterOptionsType<'nutrition'>, boolean>> }
  | { glucose?: Partial<Record<FilterOptionsType<'glucose'>, boolean>> };

const FilterPopoverContent = (props: {
  isOpen: boolean,
  filterOptions: SelectedOptionsType,
  onClose: () => void,
  onSearch: (newOptions: SelectedOptionsType) => void,
}) => {
  const { t } = useTranslation();
  const filterPopupRef = useRef<HTMLDivElement>();
  const [localSearchOptions, setLocalSearchOptions] = useState<SelectedOptionsType>(props.filterOptions);

  useEffect(() => {
    function checkIfClickedOutside(e) {
      if (filterPopupRef.current && !filterPopupRef.current.contains(e.target)) {
        props.onClose();
      }
    }
    if (props.isOpen) {
      // eslint-disable-next-line i18next/no-literal-string
      document.addEventListener('click', checkIfClickedOutside);
      return () => {
        // eslint-disable-next-line i18next/no-literal-string
        document.removeEventListener('click', checkIfClickedOutside);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isOpen, props.onClose]);

  useEffect(() => {
    setLocalSearchOptions(props.filterOptions);
  }, [props.filterOptions]);

  const toggleOption = (
    optionCategory: keyof ActivityFeedFilterOptions,
    key: FilterOptionsType<typeof optionCategory>,
  ) => {
    const newOptions = getNewOptions(localSearchOptions, optionCategory, key);
    setLocalSearchOptions(newOptions);
    props.onSearch(newOptions);
  };

  return (
    <div ref={filterPopupRef} className={classnames('search-dropdown', { 'active': props.isOpen })}>
      <div className="search-items">
        {Object.entries(FILTER_OPTIONS(t)).map(([optionCategory, options]) => (
          !((options as any).hidden) && (
            <div key={optionCategory}>
              <p className="mb-1">
                {options.label}
              </p>
              <div className="d-flex flex-wrap mb-3">
                {options.options.map(opt => (
                  <span
                    className={classnames('rx-tag', {
                      'rx-tag-active': localSearchOptions[optionCategory]?.[opt.value],
                    })}
                    key={opt.value}
                    onClick={() => toggleOption(optionCategory as keyof ActivityFeedFilterOptions, opt.value)}
                  >
                    <span>
                      {opt.label}
                    </span>
                  </span>
                ))}
              </div>
            </div>
          )
        ))}
      </div>
    </div>
  );
};

const PatientLogV2 = (props) => {
  const { patient, clinician } = useStore();
  const { t, i18n } = useTranslation();

  const [filterOptions, setFilterOptions] = useState<SelectedOptionsType>({});
  const [mouseOverFilterBox, setMouseOverFilterBox] = useState(false);
  const [filterByDate, setFilterByDate] = useState<string | Moment>(moment());
  const [selectedMeal, setSelectedMeal] = useState<
    {
      meal: MealResponse,
      activityFeedSectionIdx: number,
    } | null
  >(null);
  const [filterPopover, setFilterPopover] = useState<boolean>(false);
  const [activeDate, setActiveDate] = useState<DateTime | null>(DateTime.local());
  const timelineContainerRef = useRef<HTMLDivElement>();
  const [showAddMeal, setShowAddMeal] = useState(false);

  const filterOptionsLabels = FILTER_OPTIONS(t);

  const showSearchFilters = useMemo(() => {
    if (!filterOptions) {
      return false;
    }
    return Object.values(filterOptions).some((category) => Object.values(category).some((value) => value));
  }, [filterOptions]);

  const mealTimeline = usePatientActivityFeed({
    filterOptions,
    patientId: patient.patient_id,
    activeDate: activeDate,
  });

  const mealTimelineQuery = mealTimeline.query;

  const onFilterDateChange = (e: moment.Moment) => {
    track(ANALYTICS_EVENTS.FOOD_LOG_FILTER_USED, {
      'Filter Type': 'Date',
      'Filter Value': e.format('YYYY-MM-DD'),
      'Patient ID': patient.patient_id,
    });
    setFilterByDate(e);
    setActiveDate(DateTime.fromISO(e.toISOString()));
  };

  const executeSearch = (newOptions: SelectedOptionsType) => {
    Object.entries(newOptions).forEach(([filterType, filterValue]) => {
      track(ANALYTICS_EVENTS.FOOD_LOG_FILTER_USED, {
        'Filter Type': filterType,
        'Patient ID': patient.patient_id,
      });
    });
    setFilterOptions(newOptions);
  };

  const removeFilter = (optionCategory, filter) => {
    const newOptions = getNewOptions(filterOptions, optionCategory, filter);
    setFilterOptions(newOptions);
  };

  const activityFeedDays = useMemo(() => {
    if (showSearchFilters) {
      return mealTimeline.filteredFeedEntries;
    }
    return mealTimeline.feedEntries;
  }, [showSearchFilters, mealTimeline.feedEntries, mealTimeline.filteredFeedEntries]);

  const intersectionObserver = useRef<IntersectionObserver>();

  const lastPatientActivityRef = useCallback((entries: IntersectionObserverEntry[] | HTMLDivElement) => {
    if (intersectionObserver.current) {
      intersectionObserver.current.disconnect();
    }

    intersectionObserver.current = new IntersectionObserver((entries) => {
      if (mealTimelineQuery.isFetchingNextPage) {
        return;
      }
      if (entries[0].isIntersecting && mealTimelineQuery.hasNextPage) {
        mealTimelineQuery.fetchNextPage();
      }
    });
    if (entries) {
      intersectionObserver.current.observe(entries as any);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mealTimelineQuery.isFetchingNextPage, mealTimelineQuery.hasNextPage]);

  const handleMealCardClick = (meal: MealResponse, idx: number) => {
    track(ANALYTICS_EVENTS.MEAL_CARD_CLICKED, {
      'Meal ID': meal.id,
      'Patient ID': patient.patient_id,
      'Source': 'Food Log',
    });
    setSelectedMeal({
      meal,
      activityFeedSectionIdx: idx,
    });
  };

  return (
    <>
      <Row className="d-flex align-items-center toolbar">
        <Col xs="auto" className="d-flex ml-auto tools-wrapper align-items-center">
          {clinician.flags.clinician_allow_meal_edit && (
            <span onClick={() => setShowAddMeal(true)}>
              <i className="fas fa-plus rounded-btn" title="Add Meal" />
            </span>
          )}
          {showSearchFilters
            && (
              <div
                className={`selected-filters ${mouseOverFilterBox ? 'bigger-box' : 'selected-filters'}`}
                onMouseOver={() => setMouseOverFilterBox(true)}
                onMouseLeave={() => setMouseOverFilterBox(false)}
              >
                {Object.entries(filterOptions).map(([filterKey, filterValues]) => (
                  Object.entries(filterValues).map(([value, isSelected]) => {
                    const optionDef = filterOptionsLabels[filterKey];
                    const valueDef = optionDef?.options?.find(o => o.value === value);

                    return !!isSelected && valueDef && (
                      <span className="rx-tag" key={`${filterKey}:${value}`}>
                        {valueDef.cardLabel || valueDef.label}
                        <span
                          className="p-1 text-danger"
                          onClick={() => removeFilter(filterKey, value)}
                        >
                          <i className="fa fa-times-circle" title="Remove Filter" />
                        </span>
                      </span>
                    );
                  })
                ))}
              </div>
            )}

          <div className="position-relative">
            <span onClick={() => setFilterPopover(!filterPopover)}>
              <i className="fas fa-filter rounded-btn" title="Filter Meals" />
            </span>
            <FilterPopoverContent
              isOpen={filterPopover}
              onClose={() => setFilterPopover(false)}
              filterOptions={filterOptions || {}}
              onSearch={executeSearch}
            />
          </div>
          <span>
            <Trans>FILTER BY DATE</Trans>
          </span>
          <div>
            <MuiPickersUtilsProvider
              utils={DateFnsUtils}
              locale={i18n.language}
            >
              <DatePicker
                inputVariant="outlined"
                disableToolbar
                autoOk={true}
                maxDate={moment()}
                format="YYYY-MM-DD"
                variant="inline"
                value={filterByDate}
                onChange={onFilterDateChange}
              />
            </MuiPickersUtilsProvider>
          </div>
        </Col>
      </Row>
      <div className="timeline-container" ref={timelineContainerRef}>
        {activityFeedDays.map((section, idx) => {
          const dayView = (
            <PatientActivityLogDayView
              key={idx}
              section={section}
              openAddMealPanel={() => setShowAddMeal(true)}
              onClickItem={(item) => {
                if (item.type != 'meal-log') {
                  // for now, only meals are clickable
                  return;
                }
                handleMealCardClick(item.data, idx);
              }}
            />
          );
          if (activityFeedDays.length === idx + 1) {
            return (
              <div ref={lastPatientActivityRef} key={section.id}>
                {dayView}
                <div className="text-center scroll-loader" style={{ height: '125px' }}>
                  {mealTimelineQuery.isFetching || mealTimelineQuery.hasNextPage
                    ? <LoadingSpinner />
                    : (
                      <h5>
                        <Trans>That's everything the patient logged!</Trans>
                      </h5>
                    )}
                </div>
              </div>
            );
          }
          return dayView;
        })}
        {!activityFeedDays.length && (
          <div className="w-100 p-4 d-flex flex-column align-items-center justify-content-center">
            {mealTimelineQuery.isFetching
              ? <LoadingSpinner />
              : mealTimelineQuery.isError
              ? (
                <>
                  <h4 className="text-muted">
                    <Trans>Unexpected error loading meals</Trans>
                  </h4>
                  <span>
                    {'' + mealTimelineQuery.error}
                  </span>
                </>
              )
              : (
                <>
                  <h4 className="text-muted">
                    <Trans>No meals logged</Trans>
                  </h4>
                  {clinician.flags.clinician_allow_meal_edit && (
                    <span
                      className="addMealBtn"
                      onClick={() => setShowAddMeal(true)}
                      style={{ cursor: 'pointer' }}
                    >
                      <Trans>Add a meal</Trans> <i className="fas fa-plus" />
                    </span>
                  )}
                </>
              )}
          </div>
        )}
      </div>
      {!!selectedMeal && (
        <ShowMealV2
          selectedMeal={selectedMeal.meal}
          activityData={activityFeedDays[selectedMeal.activityFeedSectionIdx]?.data || []}
          onHide={() => setSelectedMeal(null)}
          initialMode="view"
        />
      )}
      {showAddMeal && (
        <ShowMealV2
          selectedMeal={null}
          onHide={() => setShowAddMeal(false)}
          activityData={[]}
          initialMode="add"
        />
      )}
    </>
  );
};

export default PatientLogV2;
