/* eslint-disable i18next/no-literal-string */
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import mixpanel from 'mixpanel-browser';
import React, { useEffect } from 'react';
import { ClinicianResponse, ClinicResponse } from './api/generated/models';
import { config } from './config';

export const sentryReplay = new Sentry.Replay();

/**
 * Triggers a Sentry Replay recording session while the component is mounted.
 */
export const useSentryReplay = () => {
  React.useEffect(() => {
    sentryReplay.start();
    return () => {
      sentryReplay.stop();
    };
  }, []);
};

export const initAnalytics = () => {
  Sentry.init({
    dsn: config.SENTRY_DSN,
    tunnel: config.SENTRY_TUNNEL,
    environment: config.ENV,
    release: config.COMMIT_SHA,

    integrations: [
      new BrowserTracing(),
      sentryReplay,
    ],

    tracesSampleRate: 0.1,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.0,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 0.1,
  });

  mixpanel.init(config.MIXPANEL_KEY);
  mixpanel.set_config({
    debug: config.IS_LOCAL,
    ignore_dnt: true,
  });
  mixpanel.register({
    'Domain': window.location.hostname,
  });
};

export const useAnalyticsUserIdentity = (user: ClinicianResponse & { clinics: ClinicResponse[] } | null) => {
  useEffect(() => {
    if (!user) {
      Sentry.setUser(null);
      return;
    }
    const userIdentity = {
      id: '' + user.id,
      username: `User ${user.id}`,
    };
    Sentry.setUser(userIdentity);
    mixpanel.identify('' + user.id);
    const clinicTypes = user.clinics.length > 0 && new Set(user.clinics.map(c => c.clinic_type));

    mixpanel.people.set_once({
      ...userIdentity,
      'Hospital ID': user?.hospital_id,
      'Language': user?.locale,
      ...Object.fromEntries(
        user.clinics.length > 0 ? user.clinics.map((c, i) => [`Clinic ID (${i + 1})`, c.id]) : [],
      ),
      ...Object.fromEntries(
        clinicTypes ? Array.from(clinicTypes).map(type => [`Clinic type: ${type}`, true]) : [],
      ),
      ...Object.fromEntries(
        user.flags ? Object.entries(user.flags).map(([k, v]) => [`Clinician Flag: ${k}`, v]) : [],
      ),
    });
  }, [user]);
};

export const track = (eventName: string, properties: Record<string, any> = {}) => {
  try {
    mixpanel.track(eventName, properties);
  } catch (error) {
    console.error('Mixpanel tracking error:', error);
  }
};

export const ANALYTICS_EVENTS = {
  LOGIN: 'Login',
  LOGOUT: 'Logout',
  HELP_CLICKED: 'Email Support Button Clicked',

  PATIENT_LIST_FILTER_USED: 'Patient List Filter Used',
  PATIENT_RESEND_INVITE_CLICKED: 'Patient Resend Invite Email Clicked',
  PATIENT_DELETED: 'Patient Deleted',
  PATIENT_ADDED: 'New Patient Added',
  PATIENT_EDITED: 'Patient Edited',
  CLINICIAN_ADDED: 'New Clinician Added',
  CLINICIAN_PROFILE_EDITED: 'Clinician Profile Edited',

  PATIENT_LOGGING_TRIAL_SCHEDULED: 'Patient Logging Trial Scheduled',
  TAB_CHANGED: 'Tab Changed',
  PATIENT_NOTE_SAVED: 'Patient Note Saved',
  AGP_DATE_SELECTION: 'AGP Date Selected',
  PATIENT_TIP_SENT: 'Patient Tip Sent',

  FOOD_LOG_FILTER_USED: 'Food Log Filter Used',
  MEAL_CARD_CLICKED: 'Meal Card Clicked',
  MEAL_ADDED: 'Meal Added',
  MEAL_EDITED: 'Meal Edited',
  MEAL_DELETED: 'Meal Deleted',

  GOAL_DELETED: 'Goal Deleted',
  GOAL_EDITED: 'Goal Edited',
  GOAL_ADDED: 'Goal Added',

  NUTRIENT_DATE_CHANGED: 'Nutrition Date Range Changed',
  NUTRIENT_ROW_SELECTED: 'Nutrition Row Selected',
  MEAL_HISTORY_REPORT_DOWNLOADED: 'Meal History Report Downloaded',

  RECIPE_SEARCH_SUBMITTED: 'Recipes Search Submitted',
  RECIPE_LIST_EMAIL_SENT: 'Recipe List Email Sent',

  DRAFT_REPORT_GENERATED: 'Draft Report Generated',
  DRAFT_REPORT_REGENERATED: 'Draft Report Regenerated',
  DRAFT_REPORT_DELETED: 'Draft Report Deleted',
  DRAFT_REPORT_SENT: 'Draft Report Sent',
  REPORT_VIEWED: 'Report Viewed',
  CLINICAL_SUMMARY_CLICKED: 'Clinical Summary Clicked',
} as const;
