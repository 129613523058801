import { DatePicker } from 'antd';
import moment from 'moment';
import type { Moment } from 'moment';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';
import { PresetDate, RangeValue } from 'rc-picker/lib/interface';
import { TFunc } from '../i18n';
const momentPicker = DatePicker.generatePicker<Moment>(momentGenerateConfig);
export const MomentRangePicker = momentPicker.RangePicker;

export const getRangePresets = (opts: {
  t: TFunc,
  maxMonths?: 'one' | 'two' | 'three',
  isAgp?: boolean,
}): PresetDate<RangeValue<moment.Moment>>[] => {
  const { t } = opts;
  const days = {
    today: moment().endOf('day'),
    dayAgo: moment().subtract(2, 'd').startOf('day'),
    weekAgo: moment().subtract(7, 'd').startOf('day'),
    monthAgo: moment().subtract(1, 'M').startOf('day'),
    twomonthAgo: moment().subtract(2, 'M').startOf('day'),
    threemonthAgo: moment().subtract(3, 'M').startOf('day'),
  };
  const presets = [
    { label: t('Last 7 days'), value: [days.weekAgo, days.today], showInAgp: true },
    { label: t('Today'), value: [days.today, days.today], showInAgp: false },
    { label: t('Yesterday'), value: [days.dayAgo, days.today], showInAgp: false },
    { label: t('Last month'), value: [days.monthAgo, days.today], showInAgp: true },
    { label: t('Last 2 months'), value: [days.twomonthAgo, days.today], showInAgp: true },
    { label: t('Last 3 months'), value: [days.threemonthAgo, days.today], showInAgp: true },
  ] as (PresetDate<RangeValue<moment.Moment>> & { showInAgp: boolean })[];

  // Filter out excluded presets if specified

  if (opts.maxMonths === 'one') {
    return presets.slice(0, 4);
  } else if (opts.maxMonths === 'two') {
    return presets.slice(0, 5);
  }

  return opts.isAgp ? presets.filter(p => p.showInAgp) : presets;
};
