/* eslint-disable i18next/no-literal-string */
import { useEffect, useState } from 'react';
import { config } from '../config';
/**
 * Sets an attribute on an object.
 *
 * > setattr({ foo: { bar: 42 }}, ['foo', 'bar'], 69)
 * { foo: { bar: 69 }}
 */
export function setattr(obj: any, bits: string[], value: any): any {
  if (!bits.length) {
    return value;
  }
  return {
    ...obj,
    [bits[0]]: setattr(obj[bits[0]], bits.slice(1), value),
  };
}

/**
 * Gets an attribute from an object.
 *
 * > getattr({ foo: { bar: 42 }}, ['foo', 'bar'])
 * 42
 */
export function getattr(obj: any, bits: string[]): any {
  for (const b of bits) {
    obj = obj[b];
  }
  return obj;
}

/**
 * Used to assert at compile time that a statement is unreachable. Note: this will raise an exception, even in production.
 *
 * See also: `assertUnreachableSafe`, which will not raise an exception in production.
 *
 * For example:
 *
 *  type Option = 'a' | 'b'
 *
 *  function handleOption(o: Option) {
 *    if (o == 'a')
 *      return handleA()
 *    if (o == 'b')
 *      return handleB()
 *    assertUnreachableUnsafe(o)
 *  }
 */
export function assertUnreachableUnsafe(x: never, message?: string): never {
  throw new Error(
    (message ? message + ' ' : '') + 'Reached unreachable statement: ' + JSON.stringify(x),
  );
}

/**
 * Used to assert at compile time that a statement is unreachable, but will not raise an exception in production.
 *
 * For example:
 *
 *  type Option = 'a' | 'b'
 *
 *  function handleOption(o: Option) {
 *    if (o == 'a')
 *      return handleA()
 *    if (o == 'b')
 *      return handleB()
 *    assertUnreachableSafe(o)
 *  }
 */
export function assertUnreachableSafe(x: never, message?: string): never {
  if (config.IS_LOCAL) {
    throw new Error(
      (message ? message + ' ' : '') + 'Reached unreachable statement: ' + JSON.stringify(x),
    );
  }

  return null as never;
}

/**
 * Returns true if `obj` is a function.
 */
export function isFunction(obj: any) {
  return typeof obj === 'function';
}

/**
 * Returns true if `obj` is a promise.
 */
export function isPromise(obj: any) {
  return obj && obj.then && isFunction(obj.then);
}

/**
 * Similar to `useState`, but updates the state when the value changes; ie, identical to:
 *
 *  const [state, setState] = useState(value)
 *  useEffect(() => {
 *    setState(value)
 *  }, [value])
 */
export function useUpdatingState<T>(
  value: T,
  nullDefault: NonNullable<T>,
  useEffectChangeList?: any[],
): [NonNullable<T>, (newValue: T) => void] {
  const [state, setState] = useState<T>(value);
  useEffect(() => {
    setState(value ?? nullDefault);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, ...(useEffectChangeList || [])]);

  return [state ?? nullDefault, setState];
}

/**
 * Catches any exception which might be raised by a promise and returns a
 * tuple of [result, error], where either the result or the error will be
 * undefined:
 *
 *   let [res, error] = await maybe(someApi.get(...))
 *   if (err) {
 *     return `Oh no there was an error: ${err}`
 *   }
 *   console.log('The result:', res)
 *
 * The result is also an object with `res` and `err` fields:
 *
 *   let someResult = await maybe(someApi.get(...))
 *   if (someResult.err) {
 *     return `Oh no there was an error: ${someResult.err}`
 *   }
 *   console.log('The result:', someResult.res)
 */
export type MaybeRes<T, E = any> = [T, E] & { res: T, err: E };
export type MaybeFunc<T, E = any> = {
  (p: Promise<T>): Promise<MaybeRes<T, E>>,
  accept: <T, E = any>(res: T) => MaybeRes<T, E>,
  reject: <E, T = any>(err: E) => MaybeRes<T, E>,
  unwrap: <T, E = any>(p: Promise<MaybeRes<T, E>>) => Promise<T>,
};

export function maybe<T, E = any>(p: Promise<T>): Promise<MaybeRes<T, E>> {
  return (p as Promise<T>).then(
    (res) => maybe.accept<T, E>(res),
    (err) => maybe.reject<E, T>(err),
  );
}

maybe.accept = <T, E = any>(res: T): MaybeRes<T, E> => Object.assign([res, null], { res, err: null }) as any;
maybe.reject = <E, T = any>(err: E): MaybeRes<T, E> => Object.assign([null, err], { res: null, err }) as any;
maybe.unwrap = async <T, E>(p: Promise<MaybeRes<T, E>>) => {
  const [res, err] = await p;
  if (err) {
    throw new Error(err as any);
  }
  return res;
};

export function sleep(t: number) {
  return new Promise((res) => setTimeout(res, t));
}

/**
 * Capitalize the first letter of a string, returning the rest of the string unchanged.
 */
export const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

/**
 * Gets a random element from an array
 *
 * > getRandom(['a', 'b', 'c'])
 * 'c'
 *
 * > getRandom(['a', 'b', 'c'])
 * 'a'
 */
export const getRandom = <T>(arr: [T]) => arr[Math.floor(Math.random() * arr.length)];

/**
 * Build an object from an array of [key, value] pairs:
 *
 *   > objectFromPairs([['a', 1], ['b', 2]])
 *   { a: 1, b: 2 }
 *
 * Compatible with: Object.entries proposal: https://tc39.github.io/proposal-object-from-entries/
 */
export function objectFromEntries(
  pairs: Array<[any, any]> | Iterable<[any, any]> | Iterator<[any, any]>,
): any {
  const res = {};
  // for (const pair of pairs) {
  //   res[pair[0]] = pair[1]
  // }
  return res;
}

/**
 * Safely call JSON.stringify(...) on an object, catching any possible error.
 * Additionally, if `{ shorten: ... }` is provided, the output will be
 * shortened (rendering it potentially invalid JSON).
 */
export function safeJson(obj: any, opts?: { shorten?: number }) {
  opts = opts || {};
  let res;
  try {
    res = JSON.stringify(obj);
  } catch (e) {
    res = JSON.stringify({
      error: 'safeJson error: ' + e,
      originalObj: '' + obj,
    });
  }
  if (opts.shorten) {
    res = shorten(res, opts.shorten);
  }
  return res;
}

/**
 * Shorten a string.
 *
 * > shorten('abc')
 * 'abc'
 * > shorten('abcdefg', 4)
 * 'ab…fg'
 */
export function shorten(s: string, len: number = 500) {
  if (!s || s.length <= len) {
    return s;
  }

  return s.slice(0, len / 2) + '…' + s.slice(s.length - len / 2);
}

/**
 * Removes undefined keys from 'obj'.
 *
 * > filterUndefined({ foo: 42, bar: undefined })
 * { foo: 42 }
 */
export function filterUndefined<T>(obj: T): Partial<T> {
  const res = {} as any;
  Object.entries(obj).forEach(([k, v]) => {
    if (v !== undefined) {
      res[k] = v;
    }
  });
  return res;
}

/**
 * Pluralizes a string:
 *
 *  > pluralize(1, 'Page')
 *  '1 Page'
 *  > pluralize(2, 'Page')
 *  '2 Pages'
 *  > pluralize(1, 'Ox', 'Oxen')
 *  '1 Ox'
 *  > pluralize(2, 'Ox', 'Oxen')
 *  '2 Oxen'
 *  > pluralize(1, 'is an invite', 'are {} invites')
 *  'is an invite'
 *  > pluralize(2, 'is an invite', 'are {} invites')
 *  'are 2 invites'
 *  > pluralize.exactly(1, 'an invite', 'many invites')
 *  'an invite'
 *  > pluralize.exactly(2, 'an invite', 'many invites')
 *  'many invites'
 *
 * NOTE: There is a sublte "gotcha": if neither the singular nor the plural
 * strings contain '{}', then it will be prepended to the result. This is
 * generally, but not always, useful. In cases where it's undesierable (ex,
 * because the count is being displayed somewhere else), use
 * `pluralize.exactly`.
 */
export function pluralize(count: number | string, singular: string, plural?: string) {
  const shouldPrefix = singular.indexOf('{}') < 0 && (!plural || plural.indexOf('{}') < 0);

  const prefix = shouldPrefix ? '{} ' : '';

  return pluralize.exactly(
    count,
    prefix + singular,
    plural === undefined ? plural : prefix + plural,
  );
}

pluralize.exactly = function(count: number | string, singular: string, plural?: string) {
  if (plural === undefined) {
    plural = singular + 's';
  }
  const formatStr = parseInt(count as any) == 1 ? singular : plural;
  return formatStr.replace('{}', '' + count);
};

/**
 * Format a number.
 *
 * > formatNumber(42)
 * '42'
 * > formatNumber(123456)
 * '123,456'
 * > formatNumber(null)
 * '–'
 */
export const formatNumber = (lang: string, n: number | null | undefined, nDecimals?: number) => {
  // Note: 'Intl' isn't available on all React Native platforms (ex, some Android).
  const numFormatter = typeof Intl === 'undefined'
    ? null
    : new Intl.NumberFormat(lang, {
      minimumFractionDigits: nDecimals ?? undefined,
      maximumFractionDigits: nDecimals ?? undefined,
    });

  return (
    n == null || n == undefined || isNaN(n) || !isFinite(n)
      ? '–'
      : typeof n !== 'number'
      ? n
      : !numFormatter
      ? (typeof nDecimals == 'number' ? n.toFixed(nDecimals) : '' + n)
      : numFormatter.format(n)
  );
};

export function formatGrams(lang: string, n: number) {
  return (
    n < 10
      ? formatNumber(lang, n, 1) + 'g'
      : n < 1000
      ? formatNumber(lang, n, 0) + 'g'
      : formatNumber(lang, n / 1000, 1) + 'kg'
  );
}

export function titleCase(str: string | null) {
  if (!str) {
    return str;
  }
  return str.split(' ').map(capitalize).join(' ');
}
