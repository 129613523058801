import classnames from 'classnames';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Col, Dropdown, Row } from 'react-bootstrap';
import { TFunc, Trans, useTranslation } from '../i18n';
import 'react-day-picker/lib/style.css';

import './foodFilter.scss';
import { Button } from 'react-bootstrap';
import { useStore } from '../context';
import { PatientService } from '../services/patient';

const today = moment().endOf('day');
const weekAgo = moment().subtract(7, 'd').startOf('day');
const monthAgo = moment().subtract(1, 'M').startOf('day');
const twomonthAgo = moment().subtract(2, 'M').startOf('day');
const threemonthAgo = moment().subtract(3, 'M').startOf('day');
const sixmonthAgo = moment().subtract(6, 'M').startOf('day');
const yearAgo = moment().subtract(1, 'y').startOf('day');

export const getDurationFilters = (t: TFunc) =>
  [
    [[weekAgo, today], t('last week')],
    [[monthAgo, today], t('last month')],
    [[twomonthAgo, today], t('last 2 months')],
    [[threemonthAgo, today], t('last 3 months')],
    [[sixmonthAgo, today], t('last 6 months')],
    [[yearAgo, today], t('last year')],
  ] as Array<[[moment.Moment, moment.Moment], string]>;

const FilterDropdown = (props) => {
  const handleClick = (e) => {
    e.preventDefault();
    props.onClick(e);
  };

  return (
    <a href="" onClick={handleClick}>
      {props.children}
    </a>
  );
};

const withFilterPanel = (WrappedComponent) => {
  return function() {
    const { t } = useTranslation();
    const duration_filters = getDurationFilters(t);
    const [filter, setFilter] = useState(duration_filters[0]);
    const target = useRef(null);
    const [since, until] = filter[0];
    const handleFilterClick = (f) => {
      setFilter(f);
    };

    return (
      <>
        <Row className="logFilterPanel">
          <Col className="logFilterCol">
            <span className="mr-5">
              <Trans>filter by</Trans>
            </span>

            <Dropdown className="logFilter" ref={target}>
              <Dropdown.Toggle id="log-filter-date" as={FilterDropdown as any}>
                {filter[1]} <i className="fas fa-chevron-down" />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {duration_filters.map((f, idx) => {
                  return (
                    <Dropdown.Item
                      key={idx}
                      className={classnames('filterMenuItem', { active: filter == f })}
                      onClick={() => handleFilterClick(f)}
                    >
                      {f[1]}
                      <i className="fas fa-check-circle" />
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>

        <WrappedComponent
          filter={filter}
          since={since}
          until={until}
        />
      </>
    );
  };
};

export { getDurationFilters as GetDurationFilters, withFilterPanel };
