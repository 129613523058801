import * as Sentry from '@sentry/react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { DateTime } from 'luxon';
import { useEffect } from 'react';
import { useLocalStorage } from 'usehooks-ts';
import { patientAppDataApi } from '../api';
import { useStore } from '../context';
import { maybe } from '../utils/formatters';

export type AppDataStorageValue<T> = {
  value: T,
  updatedTime: string | null,
};

export enum AppDataStorageScope {
  ghp = 'ghp',
  cgm = 'cgm',
}

export enum AppDataStoragePlatform {
  general = 'general',
  mobile = 'mobile',
}
/**
 * Store data in both LocalStorage and the server's "user_app_data" API.
 *
 * If `optimisticLoad` is `true`, the value from LocalStorage will be made
 * available immediately, and the result updated to reflect the value from
 * the API once loaded.
 *
 * For example::
 *
 *    const someState = useAppDataStorageQuery({
 *      scope: AppDataStorageScope.ghp,
 *      name: `onboarding-assessment-results',
 *      default: {
 *        foo: 'bar',
 *      },
 *      optimisticLoad: true,
 *    });
 */
export function useAppDataStorageQuery<T>(opts: {
  platform?: AppDataStoragePlatform,
  scope: AppDataStorageScope,
  name: string,
  default: T,
  optimisticLoad?: boolean,
  enabled?: boolean,
}) {
  const { patient } = useStore();
  const queryClient = useQueryClient();

  const queryKey = [
    // eslint-disable-next-line i18next/no-literal-string
    'app-data-storage',
    opts.scope,
    opts.name,
    patient?.patient_id,
  ];

  const [localStorageValue, setLocalStorageValue] = useLocalStorage<AppDataStorageValue<T | null>>(queryKey.join(':'), {
    value: opts.default,
    updatedTime: null,
  });

  const apiQuery = useQuery({
    queryKey: queryKey,
    queryFn: async () => {
      const [res, err] = await maybe(patientAppDataApi.appApiUserAppDataGetPatientAppData({
        patient_id: patient!.patient_id,
        platform: '' + (opts.platform ?? 'mobile'),
        scope: '' + opts.scope,
        name: opts.name,
      }));
      if (err) {
        if (axios.isAxiosError(err) && err.response?.status === 404) {
          return {
            value: opts.default,
            updatedTime: null,
          };
        }
        throw err;
      }

      const value = res.data.value?.[opts.name]
        ? res.data.value?.[opts.name] as T
        : (res.data.value ?? opts.default) as T;

      return {
        value,
        updatedTime: res.data.updated_time,
      };
    },
    enabled: !!patient?.patient_id && (opts.enabled ?? true),
  });

  const status = opts.optimisticLoad
    ? {
      isSuccess: true,
      isLoading: false,
      isError: false,
      error: null,
      status: 'success',
      data: localStorageValue,
      isFetching: false,
      isRefetching: false,
      failureCount: 0,
      errorUpdateCount: 0,
    }
    : apiQuery;

  /**
   * Gets the most up-to-date data (ie, the newest result from either
   * the API or LocalStorage).
   */
  const getResult = () => {
    if (!status.isSuccess) {
      return undefined;
    }
    if (!apiQuery.data?.updatedTime) {
      return localStorageValue;
    }
    if (!localStorageValue?.updatedTime) {
      return apiQuery.data;
    }
    // Note: if the two times are the same, return the API result.
    return apiQuery.data.updatedTime < localStorageValue.updatedTime
      ? localStorageValue
      : apiQuery.data;
  };
  const result = getResult();

  /**
   * In conjunction with the `useEffect` below, this function ensures that
   * the API is updated with the most recent value from LocalStorage.
   */
  const updateApiData = async (newResult: AppDataStorageValue<T | null>) => {
    if (!newResult.updatedTime) {
      const err = new Error('useAppDataStorageQuery: attempt to update API with stale data');
      console.error('' + err, newResult);
      Sentry.captureException(err);
      return;
    }

    // Optimistically assume that the API call will be successful and immediately update
    // the API query's data. This will make things feel snappy for the user, and if the
    // update happens to fail, it will be retried in the background.
    queryClient.setQueryData(queryKey, newResult);
    console.log('useAppDataStorageQuery: updating API:', newResult);

    const requestBody = Array.isArray(newResult?.value)
      ? { [opts.name]: newResult.value as any }
      : newResult?.value ?? null as any;

    const [res, err] = await maybe(patientAppDataApi.appApiUserAppDataPutPatientAppData({
      patient_id: patient!.patient_id,
      platform: opts.platform ?? 'mobile',
      scope: '' + opts.scope,
      name: opts.name,
      request_body: requestBody,
    }));
    if (err) {
      setTimeout(() => {
        const currentQueryData = queryClient.getQueryData(queryKey);
        if (currentQueryData === newResult) {
          updateApiData(newResult);
        }
      }, 1000 * 60);
      console.error('Error updating app data: ' + err, err);
      Sentry.captureException(err);
      return;
    }

    // Once the API call to update the server is successful, if there hasn't
    // been another update since then, update the query data.
    const currentQueryData = queryClient.getQueryData(queryKey);
    if (currentQueryData === newResult) {
      queryClient.setQueryData(queryKey, {
        value: res.data.value,
        updatedTime: DateTime.utc().toISO(),
      });
    }
  };

  // When ever the API query's data is out of date (ie, where the result is
  // coming from LocalStorage), update the API with the most recent value from
  // LocalStorage.
  useEffect(() => {
    if (!apiQuery.isSuccess) {
      return;
    }

    if (!result?.updatedTime) {
      return;
    }

    const shouldUpdateServer = !apiQuery.data?.updatedTime
      || apiQuery.data.updatedTime < result.updatedTime;

    if (shouldUpdateServer) {
      updateApiData(result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiQuery.isSuccess, result?.updatedTime, apiQuery.data?.updatedTime]);

  return {
    ...status,
    data: result?.value,
    updatedTime: result?.updatedTime,
    set: async (callback: (value: T | null) => T | null) => {
      try {
        const currentValue = localStorageValue?.value ?? opts.default;
        const newValue = {
          value: callback(currentValue),
          updatedTime: DateTime.utc().toISO(),
        };
        setLocalStorageValue(newValue);
      } catch (e) {
        console.error('Error setting app data storage:', e);
      }
    },
    reset: async () => {
      setLocalStorageValue({
        value: opts.default,
        updatedTime: null,
      });
    },
  };
}
