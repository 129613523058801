import { MealResponse } from '../../api/generated/models';
import { ActivityFeedItem, ActivityFeedSection } from '../../pages/patient-logV2';

export const flattenFeedEntries = (feedEntries: ActivityFeedSection[]): ActivityFeedItem[] => {
  return feedEntries.flatMap(section => section.data);
};

export const filterFeedEntriesForMealResponses = (feedEntries: ActivityFeedItem[]): MealResponse[] => {
  return feedEntries.map(entry => entry.type === 'meal-log' && entry.data) || [] as MealResponse[];
};

export const getMealResponseByMealId = (mealId: number, feedEntries: ActivityFeedItem[]): MealResponse | undefined => {
  const filteredMealResponses = filterFeedEntriesForMealResponses(feedEntries);
  return filteredMealResponses.find(meal => meal && meal.id === mealId) || undefined;
};

export const getMealsByDateAndName = (
  date: string,
  mealName: string,
  feedEntries: ActivityFeedItem[],
): MealResponse[] => {
  return filterFeedEntriesForMealResponses(feedEntries).filter(meal => {
    return meal.meal_date === date && meal.meal_name === mealName;
  });
};
