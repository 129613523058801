import { Tooltip } from '@material-ui/core';
import React, { useRef } from 'react';
import { useAsyncResult } from 'react-use-async-result';
import { SummaryStatementResponse, SummaryStatementRowResponse } from '../api/generated/models';
import { TFunc, Trans } from '../i18n';
import '../layout/layout.scss';
import '../pages/patient-reports.scss';

export const ClinicalSummaryText = (props: {
  reportClinicalSummary: SummaryStatementResponse,
  t: TFunc,
}) => {
  const { reportClinicalSummary, t } = props;
  const textRef = useRef(null);
  const copyRes = useAsyncResult();
  const copyToClipboard = () => {
    if (textRef.current) {
      const htmlContent = generateHtmlContent(textRef.current);
      const textContent = generateTextContent(textRef.current);
      copyRes.bind(navigator.clipboard.write([
        new ClipboardItem({
          'text/html': new Blob([htmlContent], { type: 'text/html' }),
          'text/plain': new Blob([textContent], { type: 'text/plain' }),
        }),
      ]));
    }
  };
  return (
    <div style={{ backgroundColor: '#F1F1F1', padding: '20px 50px 20px 30px', maxWidth: '900px' }}>
      <div style={{ cursor: 'pointer', float: 'right' }}>
        <Tooltip placement="top" title={copyRes.isDone ? t('Copied!') : t('Copy to clipboard')}>
          <i
            className="fas fa-copy"
            style={{ width: '36px', paddingLeft: '3px', color: '#909090' }}
            onClick={copyToClipboard}
          />
        </Tooltip>
      </div>
      <div ref={textRef}>
        <div>
          <Trans>Clinical Summary</Trans>
        </div>
        {formatClinicalSummary(reportClinicalSummary?.results, t)?.map((s, i) => (
          <div key={i} style={{ fontSize: '14px' }}>
            <div style={{ fontWeight: 600 }}>
              {t(s.label)}
            </div>
            <ul>{s.list}</ul>
          </div>
        ))}
      </div>
    </div>
  );
};

const formatListItem = (text: string, idx: number) => {
  if (!text) {
    return null;
  }
  const [firstWord, ...restOfStr] = text.split(' ');
  const formattedRestOfStr = restOfStr.join(' ').replace(/[\[\]]/g, '');
  return (
    <li key={idx}>
      <span style={{ fontWeight: 600 }}>{firstWord}</span>
      {' ' + formattedRestOfStr}
    </li>
  );
};
const formatClinicalSummary = (results: SummaryStatementRowResponse[], t: TFunc) => {
  const energy = results
    ?.find(s => s.sub_category === 'unhealty_fat_kcal')
    ?.text?.split(/(?=was)|(?=et trans)/)
    ?.map((s, idx) => (idx === 0 ? <span key={idx} style={{ fontWeight: 600 }}>{s.toLowerCase()}</span> : s));
  const macroDA = results
    ?.find(s => s.sub_category === 'by_type')
    ?.text;
  const formattedMacroDA = macroDA
    ?.split(/[:,]/)
    ?.map((s, idx) => (idx === 0 ? null : formatListItem(s?.trim(), idx)))
    ?.concat(<li key="energy">{energy}</li>);
  const microDA = results
    ?.filter(s => s.category === 'rdi_summary')
    ?.map(s => s.text?.split(/\bAverage\b|\bApport moyen\b/)[1]?.trim());
  const formattedMicroDA = microDA?.map((s, idx) => formatListItem(s, idx));
  const totalItems = results
    ?.find(s => s.sub_category === 'protein' && s.text.startsWith(t('A total')))
    ?.text?.split(/\.\s/);
  const frequenciesTitle = totalItems?.[0].endsWith('.') ? totalItems?.[0] : totalItems?.[0].concat('. ');
  const beverages = totalItems?.slice(1);
  const frequencies = results
    ?.filter(s => (
      (s.sub_category === 'protein'
        && !s.text.startsWith(t('A total')))
      || (s.sub_category === 'fried_food_freq')
    ))
    ?.map(s => s.text?.split(/\.\s/));
  const frequenciesBeverages = beverages ? frequencies.concat([beverages]) : frequencies;

  const formattedFrequencies = frequenciesBeverages?.map((s, idx) => {
    const firstStr = s[0]?.split(/\,\s/)?.[1] || s[0];
    const firstSentence = firstStr?.endsWith('.') ? firstStr : firstStr?.concat('. ');
    const restOfSentence = s.slice(1);
    return (
      <li key={idx}>
        <span style={{ fontWeight: 600 }}>{firstSentence}</span>
        {restOfSentence && restOfSentence.map(s => s)}
      </li>
    );
  });

  return [
    { label: t('Macronutrients (daily avg.)'), list: formattedMacroDA },
    { label: t('Micronutrients (daily avg.)'), list: formattedMicroDA },
    { label: frequenciesTitle, list: formattedFrequencies },
  ];
};

const generateHtmlContent = (element) => {
  const wrapper = document.createElement('div');
  wrapper.appendChild(element.cloneNode(true));
  // eslint-disable-next-line i18next/no-literal-string
  const elementsToRemoveAttributes = wrapper.querySelectorAll('*[class], *[id], *[style]');
  elementsToRemoveAttributes.forEach((el) => {
    el.removeAttribute('class');
    el.removeAttribute('id');
    el.removeAttribute('style');
  });
  return wrapper.innerHTML;
};

const generateTextContent = (element) => {
  const formattedText = element.innerText
    .replace(/<\/?(?:div|p|span|strong|b|em|i|ul)>/g, '')
    .replace(/<li>/g, '- ')
    .replace(/<\/li>/g, '\n');
  return formattedText;
};
