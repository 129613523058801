import _ from 'lodash';
import React from 'react';
import { TFunc } from '../../i18n';
import { ActivityFeedItem } from '../../pages/patient-logV2';
import { parseLocalDateTime } from '../../utils/formatDate';

export const cgmReadingMaxIntervalMinutes = 20;

export const glucoseChartLowerBound = 1.5;
export const glucoseChartUpperBound = 12.5;
const mmMolLToMgDl = 18.0182;

export type GlucoseUnit = 'mmol/L' | 'mg/dL';

export type GlucoseTargetsType = {
  [key in GlucoseTargetName]: GlucoseTarget & {
    valueLower: number,
    valueUpper: number,
    valueUnit: GlucoseUnit,
  };
};

export const getDefaultGlucoseTargets = (glucoseUnits: GlucoseUnit) => {
  const getValue = (value: number) =>
    _.round(
      formatGlucoseValue(glucoseUnits, value, 'mmol/L').value,
      glucoseUnits === 'mmol/L' ? 1 : 0,
    );
  return _.mapValues(_glucoseTargets, (target, _) => {
    return {
      ...target,
      valueLower: getValue(target.mmMolLower),
      valueUpper: getValue(target.mmMolUpper),
      valueUnit: glucoseUnits,
    };
  });
};

/**
 * Converts glucose values between mmol/L and mg/dL units
 * @param mmMolFlag - Target unit to convert to ('mmol/L' or 'mg/dL')
 * @param srcValue - Source glucose value to convert
 * @param srcUnit - Source unit of the value (defaults to 'mmol/L')
 * @returns Object containing converted value and unit
 * @example
 * // Convert 180 mg/dL to mmol/L
 * formatGlucoseValue('mmol/L', 180, 'mg/dL') // Returns {value: 10, unit: 'mmol/L'}
 */
export function formatGlucoseValue(
  mmMolFlag: GlucoseUnit,
  srcValue: number,
  srcUnit?: GlucoseUnit,
): { value: number, unit: GlucoseUnit } {
  if (!mmMolFlag) {
    return { value: srcValue, unit: srcUnit };
  }
  srcUnit = srcUnit ?? 'mmol/L';

  const value = mmMolFlag === 'mmol/L'
    ? (srcUnit === 'mg/dL' ? srcValue / mmMolLToMgDl : srcValue)
    : (srcUnit === 'mmol/L' ? srcValue * mmMolLToMgDl : srcValue);
  const unit = mmMolFlag === 'mmol/L' ? 'mmol/L' : 'mg/dL';
  return { value, unit };
}

export function getGlucoseChartTargetRange(glucoseRanges: GlucoseTargetsType) {
  return {
    lower: glucoseRanges.target.valueLower,
    upper: glucoseRanges.target.valueUpper,
  };
}

export const _glucoseTargets = {
  'lowDanger': {
    mmMolLower: 0,
    mmMolUpper: 3,
    color: '#981E16',
    label: 'Very low',
  },
  'low': {
    mmMolLower: 3,
    mmMolUpper: 3.9,
    color: '#DE6B6B',
    label: 'Low',
  },
  'target': {
    mmMolLower: 3.9,
    mmMolUpper: 10,
    color: '#6BA436',
    label: 'In range',
  },
  'high': {
    mmMolLower: 10,
    mmMolUpper: 13.9,
    color: '#F7CE5A',
    label: 'High',
  },
  'highDanger': {
    mmMolLower: 13.9,
    mmMolUpper: 100,
    color: '#D5712A',
    label: 'Very high',
  },
};

export const cgmStyles = {
  blue: '#528EC3',
};

export type GlucoseTargetName = keyof typeof _glucoseTargets;
export interface GlucoseTarget {
  mmMolLower: number;
  mmMolUpper: number;
  color: string;
  label: string;
}

export const mmMolToTarget = (mmMolValue: number, glucoseRanges: GlucoseTargetsType) => {
  for (const targetName in glucoseRanges) {
    const target = glucoseRanges[targetName];
    if (mmMolValue >= target.mmMolLower && mmMolValue < target.mmMolUpper) {
      return [targetName, target];
    }
  }
  return [null, null];
};

// **** ONLY USED IN OLD CGM REPORT in src/reports/cgm/CGMGlucoseChart.tsx ****
export const mmMolTargetRange = {
  lower: 4,
  upperWarn: 10,
  upperDanger: 13.3,
};

export const cgmColors = {
  bgInTarget: '#36C2B4',
  bgNearTarget: '#FFDC00',
  bgOutsideTarget: '#FF9024',
};

// export const victoryTheme = {
//   ...grayscaleTheme,
//   axis: {
//     ...grayscaleTheme.axis,
//     style: {
//       ...grayscaleTheme.axis.style,
//       axisLabel: {
//         ...grayscaleTheme.axis.style.axisLabel,
//         fontFamily: 'Publico Headline Web',
//         fill: '#7D7D7D' as any,
//         fontSize: 12,
//         padding: 25,
//       },
//       tickLabels: {
//         ...grayscaleTheme.axis.style.tickLabels,
//         fontFamily: 'geomanist',
//         fontSize: 6,
//         letterSpacing: 2,
//         padding: 4,
//       },
//       ticks: {
//         stroke: 'black',
//         size: 3,
//       },
//     },
//   },
// };

export const titleCase = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const roundToInt = (roundTo: number, value: number) => {
  return Math.round((value + roundTo) / roundTo) * roundTo;
};

export const floorToInt = (floorTo: number, value: number) => {
  return Math.floor((value + floorTo) / floorTo) * floorTo;
};

export const ceilToInt = (ceilTo: number, value: number) => {
  return Math.ceil((value + ceilTo) / ceilTo) * ceilTo;
};

/**
 * Converts a 24h time string to a 12h time string.
 * > timeStrToAmPm('11:00:00') -> '11:00 AM'
 */
export const timeStrToAmPm = (timeStr: string) => {
  // return moment(timeStr, 'HH:mm:ss').format('h:mm A');
};

/**
 * Formats a number of minutes as hours, minutes.
 * formatMinutes(65) -> '1 hour, 5 minutes'
 */
export const formatMinutes = (m: number) => {
  const hours = Math.floor(m / 60);
  const minutes = m % 60;
  // eslint-disable-next-line i18next/no-literal-string
  return `${hours} hour${hours === 1 ? '' : 's'}, ${minutes} minute${minutes === 1 ? '' : 's'}`;
};

let _useIdCounter = 0;
export const useId = () => {
  _useIdCounter += 1;
  const [id, _] = React.useState(_useIdCounter);
  return id;
};

export const resultMealDotValue = (
  glucoseValues: any[],
  type: string,
  item: any,
  idx?: number,
  minTimeStr?: string,
  maxTimeStr?: string,
): number => {
  // eslint-disable-next-line i18next/no-literal-string
  const timestamp = `${item[type + '_date']}T${item[type + '_time']}`;
  const sortedValues = _.sortBy(glucoseValues, 'timestamp');

  // eslint-disable-next-line i18next/no-literal-string
  const minTime = minTimeStr && minTimeStr.replace(' ', 'T'); // Convert '2020-01-01 00:00:00' to '2020-01-01T00:00:00'
  // eslint-disable-next-line i18next/no-literal-string
  const maxTime = maxTimeStr && maxTimeStr.replace(' ', 'T');

  const nearestPoint = sortedValues.find(point => (
    point.timestamp > timestamp
    && (!minTime || point.timestamp >= minTime)
    && (!maxTime || point.timestamp <= maxTime)
  ));

  const offset = idx ? (idx % 3) / 2 : 0;
  return offset + (nearestPoint == null ? 0 : nearestPoint.value);
};

export type CgmMealAndPatient = {
  patient_id: number,
  meal_date: string,
  meal_time: string,
  activityData?: ActivityFeedItem[],
};

export const getCgmPostPrandialTimeRange = (meal: CgmMealAndPatient) => {
  if (!meal) {
    return {
      patientId: null,
      mealTime: null,
      timeSinceLocal: null,
      timeUntilLocal: null,
    };
  }

  const mealTime = parseLocalDateTime(meal.meal_date, meal.meal_time);
  return {
    patientId: meal.patient_id,
    mealTime: mealTime,
    timeSinceLocal: new Date(mealTime.getTime() - 30 * 60 * 1000),
    timeUntilLocal: new Date(mealTime.getTime() + 4 * 60 * 60 * 1000),
  };
};

export const mmMolToMgDl = (mmMolValue: number) => {
  return mmMolValue * mmMolLToMgDl;
};

export const glucoseLabelsTr = (t: TFunc) => ({
  'Very low': t('Very Low'),
  'Low': t('Low'),
  'In range': t('In Range'),
  'High': t('High'),
  'Very high': t('Very High'),
});
