/* tslint:disable */
/* eslint-disable */
/**
 * InnerAnalytics MNT Server
 * InnerAnalytics MNT server API specification.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { UserAppDataItemResponse } from '../models';
/**
 * PatientAppDataApi - axios parameter creator
 * @export
 */
export const PatientAppDataApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get a specific value saved by the app
         * @param {number} patient_id 
         * @param {string} platform 
         * @param {string} scope 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiUserAppDataGetPatientAppData: async (patient_id: number, platform: string, scope: string, name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiUserAppDataGetPatientAppData', 'patient_id', patient_id)
            // verify required parameter 'platform' is not null or undefined
            assertParamExists('appApiUserAppDataGetPatientAppData', 'platform', platform)
            // verify required parameter 'scope' is not null or undefined
            assertParamExists('appApiUserAppDataGetPatientAppData', 'scope', scope)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('appApiUserAppDataGetPatientAppData', 'name', name)
            const localVarPath = `/patients/{patient_id}/app-data/{platform}/{scope}/{name}`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)))
                .replace(`{${"platform"}}`, encodeURIComponent(String(platform)))
                .replace(`{${"scope"}}`, encodeURIComponent(String(scope)))
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a specific value saved by the app
         * @param {number} patient_id 
         * @param {string} platform 
         * @param {string} scope 
         * @param {string} name 
         * @param {{ [key: string]: any; } | null} request_body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiUserAppDataPutPatientAppData: async (patient_id: number, platform: string, scope: string, name: string, request_body: { [key: string]: any; } | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiUserAppDataPutPatientAppData', 'patient_id', patient_id)
            // verify required parameter 'platform' is not null or undefined
            assertParamExists('appApiUserAppDataPutPatientAppData', 'platform', platform)
            // verify required parameter 'scope' is not null or undefined
            assertParamExists('appApiUserAppDataPutPatientAppData', 'scope', scope)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('appApiUserAppDataPutPatientAppData', 'name', name)
            // verify required parameter 'request_body' is not null or undefined
            assertParamExists('appApiUserAppDataPutPatientAppData', 'request_body', request_body)
            const localVarPath = `/patients/{patient_id}/app-data/{platform}/{scope}/{name}`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)))
                .replace(`{${"platform"}}`, encodeURIComponent(String(platform)))
                .replace(`{${"scope"}}`, encodeURIComponent(String(scope)))
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request_body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get app data by query
         * @param {number} patient_id 
         * @param {string} platform 
         * @param {string} scope 
         * @param {string} [key_prefix] 
         * @param {number} [limit] 
         * @param {string} [offset] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiUserAppDataQueryPatientAppData: async (patient_id: number, platform: string, scope: string, key_prefix?: string, limit?: number, offset?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiUserAppDataQueryPatientAppData', 'patient_id', patient_id)
            // verify required parameter 'platform' is not null or undefined
            assertParamExists('appApiUserAppDataQueryPatientAppData', 'platform', platform)
            // verify required parameter 'scope' is not null or undefined
            assertParamExists('appApiUserAppDataQueryPatientAppData', 'scope', scope)
            const localVarPath = `/patients/{patient_id}/app-data/query`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (platform !== undefined) {
                localVarQueryParameter['platform'] = platform;
            }

            if (scope !== undefined) {
                localVarQueryParameter['scope'] = scope;
            }

            if (key_prefix !== undefined) {
                localVarQueryParameter['key_prefix'] = key_prefix;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PatientAppDataApi - functional programming interface
 * @export
 */
export const PatientAppDataApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PatientAppDataApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get a specific value saved by the app
         * @param {number} patient_id 
         * @param {string} platform 
         * @param {string} scope 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiUserAppDataGetPatientAppData(patient_id: number, platform: string, scope: string, name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAppDataItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiUserAppDataGetPatientAppData(patient_id, platform, scope, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a specific value saved by the app
         * @param {number} patient_id 
         * @param {string} platform 
         * @param {string} scope 
         * @param {string} name 
         * @param {{ [key: string]: any; } | null} request_body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiUserAppDataPutPatientAppData(patient_id: number, platform: string, scope: string, name: string, request_body: { [key: string]: any; } | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAppDataItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiUserAppDataPutPatientAppData(patient_id, platform, scope, name, request_body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get app data by query
         * @param {number} patient_id 
         * @param {string} platform 
         * @param {string} scope 
         * @param {string} [key_prefix] 
         * @param {number} [limit] 
         * @param {string} [offset] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiUserAppDataQueryPatientAppData(patient_id: number, platform: string, scope: string, key_prefix?: string, limit?: number, offset?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserAppDataItemResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiUserAppDataQueryPatientAppData(patient_id, platform, scope, key_prefix, limit, offset, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PatientAppDataApi - factory interface
 * @export
 */
export const PatientAppDataApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PatientAppDataApiFp(configuration)
    return {
        /**
         * 
         * @summary Get a specific value saved by the app
         * @param {PatientAppDataApiAppApiUserAppDataGetPatientAppDataRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiUserAppDataGetPatientAppData(requestParameters: PatientAppDataApiAppApiUserAppDataGetPatientAppDataRequest, options?: AxiosRequestConfig): AxiosPromise<UserAppDataItemResponse> {
            return localVarFp.appApiUserAppDataGetPatientAppData(requestParameters.patient_id, requestParameters.platform, requestParameters.scope, requestParameters.name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a specific value saved by the app
         * @param {PatientAppDataApiAppApiUserAppDataPutPatientAppDataRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiUserAppDataPutPatientAppData(requestParameters: PatientAppDataApiAppApiUserAppDataPutPatientAppDataRequest, options?: AxiosRequestConfig): AxiosPromise<UserAppDataItemResponse> {
            return localVarFp.appApiUserAppDataPutPatientAppData(requestParameters.patient_id, requestParameters.platform, requestParameters.scope, requestParameters.name, requestParameters.request_body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get app data by query
         * @param {PatientAppDataApiAppApiUserAppDataQueryPatientAppDataRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiUserAppDataQueryPatientAppData(requestParameters: PatientAppDataApiAppApiUserAppDataQueryPatientAppDataRequest, options?: AxiosRequestConfig): AxiosPromise<Array<UserAppDataItemResponse>> {
            return localVarFp.appApiUserAppDataQueryPatientAppData(requestParameters.patient_id, requestParameters.platform, requestParameters.scope, requestParameters.key_prefix, requestParameters.limit, requestParameters.offset, requestParameters.order, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for appApiUserAppDataGetPatientAppData operation in PatientAppDataApi.
 * @export
 * @interface PatientAppDataApiAppApiUserAppDataGetPatientAppDataRequest
 */
export interface PatientAppDataApiAppApiUserAppDataGetPatientAppDataRequest {
    /**
     * 
     * @type {number}
     * @memberof PatientAppDataApiAppApiUserAppDataGetPatientAppData
     */
    readonly patient_id: number

    /**
     * 
     * @type {string}
     * @memberof PatientAppDataApiAppApiUserAppDataGetPatientAppData
     */
    readonly platform: string

    /**
     * 
     * @type {string}
     * @memberof PatientAppDataApiAppApiUserAppDataGetPatientAppData
     */
    readonly scope: string

    /**
     * 
     * @type {string}
     * @memberof PatientAppDataApiAppApiUserAppDataGetPatientAppData
     */
    readonly name: string
}

/**
 * Request parameters for appApiUserAppDataPutPatientAppData operation in PatientAppDataApi.
 * @export
 * @interface PatientAppDataApiAppApiUserAppDataPutPatientAppDataRequest
 */
export interface PatientAppDataApiAppApiUserAppDataPutPatientAppDataRequest {
    /**
     * 
     * @type {number}
     * @memberof PatientAppDataApiAppApiUserAppDataPutPatientAppData
     */
    readonly patient_id: number

    /**
     * 
     * @type {string}
     * @memberof PatientAppDataApiAppApiUserAppDataPutPatientAppData
     */
    readonly platform: string

    /**
     * 
     * @type {string}
     * @memberof PatientAppDataApiAppApiUserAppDataPutPatientAppData
     */
    readonly scope: string

    /**
     * 
     * @type {string}
     * @memberof PatientAppDataApiAppApiUserAppDataPutPatientAppData
     */
    readonly name: string

    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof PatientAppDataApiAppApiUserAppDataPutPatientAppData
     */
    readonly request_body: { [key: string]: any; } | null
}

/**
 * Request parameters for appApiUserAppDataQueryPatientAppData operation in PatientAppDataApi.
 * @export
 * @interface PatientAppDataApiAppApiUserAppDataQueryPatientAppDataRequest
 */
export interface PatientAppDataApiAppApiUserAppDataQueryPatientAppDataRequest {
    /**
     * 
     * @type {number}
     * @memberof PatientAppDataApiAppApiUserAppDataQueryPatientAppData
     */
    readonly patient_id: number

    /**
     * 
     * @type {string}
     * @memberof PatientAppDataApiAppApiUserAppDataQueryPatientAppData
     */
    readonly platform: string

    /**
     * 
     * @type {string}
     * @memberof PatientAppDataApiAppApiUserAppDataQueryPatientAppData
     */
    readonly scope: string

    /**
     * 
     * @type {string}
     * @memberof PatientAppDataApiAppApiUserAppDataQueryPatientAppData
     */
    readonly key_prefix?: string

    /**
     * 
     * @type {number}
     * @memberof PatientAppDataApiAppApiUserAppDataQueryPatientAppData
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof PatientAppDataApiAppApiUserAppDataQueryPatientAppData
     */
    readonly offset?: string

    /**
     * 
     * @type {string}
     * @memberof PatientAppDataApiAppApiUserAppDataQueryPatientAppData
     */
    readonly order?: string
}

/**
 * PatientAppDataApi - object-oriented interface
 * @export
 * @class PatientAppDataApi
 * @extends {BaseAPI}
 */
export class PatientAppDataApi extends BaseAPI {
    /**
     * 
     * @summary Get a specific value saved by the app
     * @param {PatientAppDataApiAppApiUserAppDataGetPatientAppDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientAppDataApi
     */
    public appApiUserAppDataGetPatientAppData(requestParameters: PatientAppDataApiAppApiUserAppDataGetPatientAppDataRequest, options?: AxiosRequestConfig) {
        return PatientAppDataApiFp(this.configuration).appApiUserAppDataGetPatientAppData(requestParameters.patient_id, requestParameters.platform, requestParameters.scope, requestParameters.name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a specific value saved by the app
     * @param {PatientAppDataApiAppApiUserAppDataPutPatientAppDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientAppDataApi
     */
    public appApiUserAppDataPutPatientAppData(requestParameters: PatientAppDataApiAppApiUserAppDataPutPatientAppDataRequest, options?: AxiosRequestConfig) {
        return PatientAppDataApiFp(this.configuration).appApiUserAppDataPutPatientAppData(requestParameters.patient_id, requestParameters.platform, requestParameters.scope, requestParameters.name, requestParameters.request_body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get app data by query
     * @param {PatientAppDataApiAppApiUserAppDataQueryPatientAppDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientAppDataApi
     */
    public appApiUserAppDataQueryPatientAppData(requestParameters: PatientAppDataApiAppApiUserAppDataQueryPatientAppDataRequest, options?: AxiosRequestConfig) {
        return PatientAppDataApiFp(this.configuration).appApiUserAppDataQueryPatientAppData(requestParameters.patient_id, requestParameters.platform, requestParameters.scope, requestParameters.key_prefix, requestParameters.limit, requestParameters.offset, requestParameters.order, options).then((request) => request(this.axios, this.basePath));
    }
}

