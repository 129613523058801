/* eslint-disable i18next/no-literal-string */
const env = process.env.REACT_APP_IA_ENV || 'local';

export function envswitch<T>(vals: { local?: T, stg?: T, prod?: T, test?: T }): T {
  const envName = {
    staging: 'stg',
    production: 'prod',
  }[env] || env;
  const res = (vals as any)[envName];
  if (res === undefined) {
    throw new Error(`No value specified for env '${env}' in ${JSON.stringify(vals)}`);
  }
  return res;
}

export class ConfigBase {
  ENV = env;

  COMMIT_SHA = process.env.REACT_APP_COMMIT_SHA || 'local';
  BUILD_DATE = process.env.REACT_APP_BUILD_DATE || 'local';
  RELEASE = process.env.REACT_APP_RELEASE || (
    this.BUILD_DATE == 'local'
      ? 'local'
      : `${this.BUILD_DATE}/${this.COMMIT_SHA.slice(0, 7)}`
  );

  IS_LOCAL = envswitch({
    local: true,
    stg: false,
    prod: false,
    test: false,
  });

  IS_DEV = envswitch({
    local: true,
    stg: true,
    prod: false,
    test: false,
  });

  IS_PROD = envswitch({
    local: false,
    stg: false,
    prod: true,
    test: false,
  });

  IS_TEST = envswitch({
    local: false,
    stg: false,
    prod: false,
    test: true,
  });
}

export class IAClinicianConfig extends ConfigBase {
  API_URL = process.env.REACT_APP_API_URL || envswitch({
    local: 'http://localhost:1480',
    // local: 'https://stg-api.rxdev.co',
    stg: 'https://stg-api.rxdev.co',
    prod: 'https://api.rxfoodapp.com',
  });

  SENTRY_DSN = envswitch({
    local: 'https://4d66d90973fef9519a0c7f2aeac04100@o1345580.ingest.sentry.io/4506435015540736',
    stg: 'https://4d66d90973fef9519a0c7f2aeac04100@o1345580.ingest.sentry.io/4506435015540736',
    prod: 'https://b99832378d85c68f3c3c6cc8f32c0b77@o1345580.ingest.sentry.io/4506075167588352',
  });

  SENTRY_TUNNEL = envswitch({
    local: 'https://sentry-tunnel-zt4ajolhua-nn.a.run.app/',
    stg: 'https://sentry-tunnel-zt4ajolhua-nn.a.run.app/',
    prod: 'https://sentry-tunnel-em3etxqkxa-nn.a.run.app/',
  });

  MIXPANEL_KEY = envswitch({
    local: '72459a763216c2dd5e2f3c0db7d8c15f',
    stg: '72459a763216c2dd5e2f3c0db7d8c15f',
    prod: '8279a5b2db9a998a6c3abf799cdf65da',
  });
}

export const config = new IAClinicianConfig();
