import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { ANALYTICS_EVENTS, track } from '../analytics';
import { FilterType, withFilterPanel } from '../components/foodFilterNew';
import { LabelG } from '../components/label';
import { useStore } from '../context';
import './patient-nutrient.scss';
import { useQuery } from '@tanstack/react-query';
import _ from 'lodash';
import { Trans } from 'react-i18next';
import { patientApi, reportingApi, waterApi } from '../api';
import {
  GoalExtendedWeeklyReportResponse,
  PatientNutritionalDetailsTableAggVal,
  PatientNutritionDetailsTableRow,
  RdiResponse,
} from '../api/generated/models';
import { LoadingSpinner } from '../components/loadingSpinner';
import { ShowNutrientDetails } from '../components/showNutrientDetails';
import { TFunc, useTranslation } from '../i18n';
import { useGoals } from '../services/goal';
import { formatNumber } from '../utils/formatters';
import { NUTRIENT_TABLE_ROWS, nutrientGetDef } from './nutrients';
import { ActivityFeedItem } from './patient-logV2';

const handleParam = (key: string | null, value?: string | null) => {
  const params = new URLSearchParams(location.search);
  const hasParams = params.toString() !== '';
  if (value === undefined) {
    value = params.get(key);
  } else if (value === null) {
    params.delete(key);
  } else {
    params.set(key, value);
  }
  // eslint-disable-next-line i18next/no-literal-string
  const target = params.toString() ? location.pathname + '?' + params.toString() : location.pathname;
  hasParams
    ? history.replaceState({ path: value }, '', target)
    : history.pushState({ path: value }, '', target);
  return value;
};

export const useQueryParam = (key: string | null): [string | null, (newValue: string | null) => void] => {
  const [param, setParam] = useState(handleParam(key));
  const setter = (newValue: string | null) => setParam(handleParam(key, newValue));
  return [param, setter];
};

const getColumnHeaders = (t: TFunc) => [
  t('Data'),
  t('Breakfast'),
  t('Lunch'),
  t('Dinner'),
  t('Snack'),
  t('Total'),
  t('RDI'),
];

/* eslint-disable-next-line i18next/no-literal-string */
const mealNameCols = ['breakfast', 'lunch', 'dinner', 'snack'];

export type NutrientRowDataType = {
  field: typeof NUTRIENT_TABLE_ROWS[number],
  label: string,
  breakfast: string,
  lunch: string,
  dinner: string,
  snack: string,
  total: string,
  rdi: string,
};

const Nutrient = (props: {
  filter: FilterType,
  enumeratedDays: moment.Moment[],
  filteredFeedEntries: ActivityFeedItem[],
}) => {
  const { patient } = useStore();
  const { goals } = useGoals();
  const { filter, enumeratedDays, filteredFeedEntries } = props;
  const { t, i18n } = useTranslation();

  const getSortedNutrientRows = (goals: GoalExtendedWeeklyReportResponse[], patientId: number) => {
    const movedItems = NUTRIENT_TABLE_ROWS.filter(nutrient => {
      return goals.some(g => {
        return nutrient === 'meal_date_count'
          || (g.patient_id === patientId && g.goal_type === 'nutrient' && g?.target_name === nutrient);
      });
    });
    const remainingItems = NUTRIENT_TABLE_ROWS.filter(nutrient => !movedItems.includes(nutrient));
    return [...movedItems, ...remainingItems];
  };

  const [since, until] = filter.rangePickerValue.map(m => m.format('YYYY-MM-DD'));
  const formattedDateRanges = filter.dateRanges.map(range => ({ gte: range[0], lte: range[1] }));

  const mealDateQuery = useQuery(['mealDateQuery', formattedDateRanges, patient.patient_id], async () => {
    const res = await reportingApi.appApiPatientReportsApiGetPatientNutritionDetailsTable({
      patient_id: patient.patient_id,
      group_by: 'meal_date',
      filter: JSON.stringify({
        meal_date: {
          or: formattedDateRanges,
        },
      }),
    });
    return res.data;
  }, {
    refetchOnMount: false,
    staleTime: 1000 * 60,
  });
  const nutrientQuery = useQuery(['nutrientFilter', formattedDateRanges, patient.patient_id], async () => {
    const res = await reportingApi.appApiPatientReportsApiGetPatientNutritionDetailsTable({
      patient_id: patient.patient_id,
      group_by: 'meal_name',
      filter: JSON.stringify({
        meal_date: {
          or: formattedDateRanges,
        },
      }),
    });
    return res.data;
  }, {
    refetchOnMount: false,
    staleTime: 1000 * 60,
  });
  const waterQuery = useQuery(['water', since, until, patient.patient_id], async () => {
    const res = await waterApi.appApiWaterGetWaters({
      patient_id: patient.patient_id,
      date_since: since,
      date_until: until,
    });
    return res.data;
  }, {
    refetchOnMount: false,
    staleTime: 1000 * 60,
  });
  const rdiQuery = useQuery(['rdi', patient.patient_id], async () => {
    const res = await patientApi.appApiReferenceDailyIntakeGetRdi({
      patient_id: patient.patient_id,
    });
    return res.data;
  }, {
    refetchOnMount: false,
    staleTime: 1000 * 60,
  });

  const totalMealDates = mealDateQuery.data?.rows?.length;
  const nutrientData = nutrientQuery.data?.rows;
  const waterData = waterQuery.data;
  const rdiData = Array.isArray(rdiQuery.data) ? rdiQuery.data[0] : rdiQuery.data as RdiResponse;

  const formattedRdis = rdiData?.nutrients
    ?.filter(item => {
      return item.classification === 'daily_rda' && item.nutrient !== 'protein' && item.nutrient !== 'carbohydrate'
        && item.nutrient !== 'water' && item.unit !== '%_kcal';
    })
    ?.map(item => {
      // eslint-disable-next-line i18next/no-literal-string
      const nutrient = NUTRIENT_TABLE_ROWS?.find(n => n.includes(item.nutrient.split(' ').join('_').trim()));
      return nutrient
        ? {
          nutrient,
          targetHigh: item.value_high,
          targetLow: item.value_low,
        }
        : null;
    })
    ?.filter(Boolean);

  const getRdi = (nutrient: typeof NUTRIENT_TABLE_ROWS[number]) => {
    const rdi = formattedRdis?.find((obj) => obj.nutrient === nutrient);
    return rdi && rdi?.targetHigh ? rdi.targetHigh + '' : rdi?.targetLow ? rdi.targetLow + '' : '–';
  };

  const getCellVal = (
    nutrient: keyof PatientNutritionDetailsTableRow | keyof PatientNutritionalDetailsTableAggVal,
    mealName: string,
  ): number => {
    const row = nutrientData?.find((obj) => obj.meal_name === mealName);
    if (!row) {
      return 0;
    }
    return (nutrient == 'meal_date_count' || nutrient == 'meal_item_count')
      ? row[nutrient]
      : row[nutrient].sum;
  };

  const sortedNutrientRows = getSortedNutrientRows(goals, patient.patient_id);

  const tableData = sortedNutrientRows?.map((nutrient) => {
    const rowData: NutrientRowDataType = {
      field: nutrient,
      label: '',
      breakfast: '',
      lunch: '',
      dinner: '',
      snack: '',
      total: '',
      rdi: '',
    };

    const nutrientDef = nutrientGetDef(nutrient as keyof PatientNutritionDetailsTableRow | 'water_cups');
    rowData.label = nutrientDef.suffix
      ? `${t(nutrientDef.label)} (${nutrientDef.suffix})`
      : `${t(nutrientDef.label)}`;
    rowData.rdi = getRdi(nutrient);

    if (nutrient === 'water_cups') {
      mealNameCols.map(mealName => rowData[mealName] = '–');
      return rowData;
    }

    const averageOverKey = nutrient.includes('cc_') ? 'meal_item_count' : 'meal_date_count';
    const valueSuffix = nutrient.includes('cc_') ? '%' : '';

    const values = [];
    const divisors = [];

    mealNameCols.forEach((meal, idx) => {
      const cellVal = getCellVal(nutrient, meal);
      const div = getCellVal(averageOverKey, meal);

      const value = nutrient == 'meal_date_count'
        ? cellVal
        : nutrient.includes('cc_')
        ? (cellVal / div) * 100
        : cellVal / totalMealDates;

      rowData[meal] = formatNumber(i18n.language, value, 0) + valueSuffix;

      if (value == value) {
        values.push(value);
        divisors.push(div);
      }
    });

    const totalVal = nutrient == 'meal_date_count'
      ? _.sum(values)
      : nutrient.includes('cc_')
      ? (_.sum(_.zip(values, divisors).map(([v, d]) => (v / 100 * d))) / _.sum(divisors)) * 100
      : _.sum(values);

    rowData.total = formatNumber(i18n.language, totalVal, 0) + valueSuffix;

    return rowData;
  });
  const waterSum = waterData?.map(w => w.cups).reduce((a, b) => a + b, 0);
  const avgWaters = waterSum / Number(tableData?.find((item) => item.field == 'meal_date_count').total);
  const waterRow = tableData?.find((item) => item.field == 'water_cups');
  if (waterRow) {
    waterRow.total = formatNumber(i18n.language, avgWaters, 1) + '';
  }

  const columnHeaders = getColumnHeaders(t);

  // eslint-disable-next-line i18next/no-literal-string
  const [q, setQ] = useQueryParam('nutrient');
  const selectedNutrient = tableData.find((row) => row.field == q) || null;

  useEffect(() => {
    if (!location.search && q) {
      setQ(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  return (
    <div style={{ display: 'flex', minWidth: '800px' }}>
      {nutrientQuery.isError
        ? (
          <div>
            <Trans>Unexpected error loading nutrients</Trans>: {'' + nutrientQuery.error}
          </div>
        )
        : nutrientQuery.isLoading
        ? <LoadingSpinner />
        : (
          <div style={{ width: '100%' }}>
            <Table>
              <thead>
                <tr className="totalRow">
                  {columnHeaders.map((column, idx) => (
                    <th key={idx}>
                      <LabelG>{column}</LabelG>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {tableData?.map((row, idx) => (
                  <tr
                    key={idx}
                    style={{ height: '56px' }}
                    onClick={() => {
                      (!row.field.startsWith('cc_') && !row.field.startsWith('water')) && setQ(row?.field || null);
                      track(ANALYTICS_EVENTS.NUTRIENT_ROW_SELECTED, {
                        'Patient ID': patient.patient_id,
                        'Nutrient': row?.field,
                      });
                    }}
                    className={row?.field == selectedNutrient?.field ? 'oddCol' : null}
                  >
                    <th>
                      <LabelG>{row?.label}</LabelG>
                    </th>
                    <td>{row?.breakfast}</td>
                    <td>{row?.lunch}</td>
                    <td>{row?.dinner}</td>
                    <td>{row?.snack}</td>
                    <td className="totalRow">{row?.total}</td>
                    <td>{row?.rdi}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}
      {!!selectedNutrient && (
        <ShowNutrientDetails
          filter={filter}
          enumeratedDays={enumeratedDays}
          columnHeaders={columnHeaders}
          selectedNutrient={selectedNutrient}
          filteredFeedEntries={filteredFeedEntries}
          rdiValue={getRdi(selectedNutrient.field)}
          onHide={() => setQ(null)}
        />
      )}
    </div>
  );
};

export default withFilterPanel(Nutrient);
