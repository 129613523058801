import { useQueryClient } from '@tanstack/react-query';
import classnames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useAsyncResult } from 'react-use-async-result';
import {
  CreateGoalRequestChangeEnum,
  CreateGoalRequestGoalTypeEnum,
  CreateGoalRequestPeriodEnum,
  UpdateGoalRequestPeriodEnum,
} from '../api/generated';
import { Input } from '../components/input';
import { useStore, useStoreDispatch } from '../context';
import { Trans, useTranslation } from '../i18n';
import { useGoals } from '../services/goal';
import { idToStr } from '../utils/idStr';
import { NUTRIENT_TABLE_ROWS } from './nutrients';
import './patient-goals.scss';
import { ANALYTICS_EVENTS, track } from '../analytics';
import { getGoalTargetOptions } from '../utils/nutrientTranslations';

// eslint-disable-next-line i18next/no-literal-string
const GetNutrientTime = (t) => [
  [t('day'), t('per day')],
  [t('week'), t('per week')],
  [t('breakfast'), t('per breakfast')],
  [t('lunch'), t('per lunch')],
  [t('dinner'), t('per dinner')],
  [t('snack'), t('per snack')],
];

const GetFrequencyTime = (t) => [
  [t('day'), t('per day')],
  [t('week'), t('per week')],
  [t('breakfast'), t('per breakfast')],
  [t('lunch'), t('per lunch')],
  [t('dinner'), t('per dinner')],
  [t('snack'), t('per snack')],
];

export const renderGoalText = (goal, t) => {
  let change = goal.change;
  let goalDetail = '';

  if (goal.goal_type == 'nutrient') {
    change = { increase: t('More than'), decrease: t('Less than') }[change];
    // goalDetail = `${change} ${goal.target_value}${goal.target_unit} of ${goal.target_display_name} per ${goal.period}`;
    goalDetail = `${change}`;
  }
  if (goal.goal_type == 'frequency') {
    change = { increase: t('Increase'), decrease: t('Decrease') }[change];
    // goalDetail = `${change} ${goal.target_display_name} to ${goal.target_value} times every ${goal.period}`;
    goalDetail = `${change}`;
  }
  return goalDetail;
};

export const Goals = (props) => {
  const { addNotification } = useStoreDispatch();
  const { patient } = useStore();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const nutrientTime = GetNutrientTime(t);
  const frequencyTime = GetFrequencyTime(t);

  const goalService = useGoals();
  const goals = goalService.goals;
  const goalTargets = goalService.goalTargets;

  // Toggling add & edit mode
  const [editMode, setEditMode] = useState({
    nutrient: false,
    food: false,
    goal_id: '',
  });
  // Nutrient/Food Name
  const [targetName, setTargetName] = useState({
    nutrient1: 'carbohydrate_g',
    nutrient2: 'protein_g',
    nutrient3: 'fiber_g',
    nutrient4: 'energy_kcal',
    nutrient5: '',
    food: '',
  });
  // Goal Type: Increase/Decrease, More/Less than
  const [targetChange, setChange] = useState({
    nutrient1: '',
    nutrient2: '',
    nutrient3: '',
    nutrient4: '',
    nutrient5: '',
    food: '',
  });
  // Target Value (str containing positive real numbers)
  const [targetValue, setTargetValue] = useState({
    nutrient1: '',
    nutrient2: '',
    nutrient3: '',
    nutrient4: '',
    nutrient5: '',
    food: '',
  });
  // Per day/week : string
  const [targetPeriod, setTargetPeriod] = useState({
    nutrient1: '',
    nutrient2: '',
    nutrient3: '',
    nutrient4: '',
    nutrient5: '',
    food: '',
  });
  // flag to enable adding patient goals
  const [isDisabled, setDisabled] = useState({
    nutrient1: true,
    nutrient2: true,
    nutrient3: true,
    nutrient4: true,
    nutrient5: true,
    food: true,
  });

  const [isDeleted, setDeleted] = useState({
    nutrient1: false,
    nutrient2: false,
    nutrient3: false,
    nutrient4: false,
    nutrient5: false,
  });

  // Creates map of all nutrients and foods in patients current goals
  const currentGoalsMap = new Map();
  for (const goal in Object.values(goals)) {
    currentGoalsMap.set(goals[goal].target_name, true);
  }

  // Enables adding goals if all fields are completed
  useEffect(() => {
    setDisabled(prevState => ({
      ...prevState,
      nutrient1: !targetName.nutrient1 || !targetValue.nutrient1 || !targetPeriod.nutrient1
        || !targetChange.nutrient1,
      nutrient2: !targetName.nutrient2 || !targetValue.nutrient2 || !targetPeriod.nutrient2
        || !targetChange.nutrient2,
      nutrient3: !targetName.nutrient3 || !targetValue.nutrient3 || !targetPeriod.nutrient3
        || !targetChange.nutrient3,
      nutrient4: !targetName.nutrient4 || !targetValue.nutrient4 || !targetPeriod.nutrient4
        || !targetChange.nutrient4,
      nutrient5: !targetName.nutrient5 || !targetValue.nutrient5 || !targetPeriod.nutrient5
        || !targetChange.nutrient5,
      food: !targetName.food || !targetValue.food || !targetPeriod.food || !targetChange.food,
    }));
  }, [
    targetName.nutrient1,
    targetValue.nutrient1,
    targetPeriod.nutrient1,
    targetChange.nutrient1,
    targetName.nutrient2,
    targetValue.nutrient2,
    targetPeriod.nutrient2,
    targetChange.nutrient2,
    targetName.nutrient3,
    targetValue.nutrient3,
    targetPeriod.nutrient3,
    targetChange.nutrient3,
    targetName.nutrient4,
    targetValue.nutrient4,
    targetPeriod.nutrient4,
    targetChange.nutrient4,
    targetName.nutrient5,
    targetValue.nutrient5,
    targetPeriod.nutrient5,
    targetChange.nutrient5,
    targetName.food,
    targetValue.food,
    targetPeriod.food,
    targetChange.food,
  ]);

  const saveRes = useAsyncResult();

  const handleAddGoal = async (field_id) => {
    // if (isDisabled.nutrient) {
    //   return;
    // }
    const patient_id = patient.patient_id;
    let goal_type = '';
    let period = '';
    let change = '';
    let target_name = '';
    let target_value = '';

    switch (field_id) {
      case 'nutrient1':
        // eslint-disable-next-line i18next/no-literal-string
        goal_type = 'nutrient';
        change = targetChange.nutrient1;
        period = targetPeriod.nutrient1;
        target_name = targetName.nutrient1;
        target_value = targetValue.nutrient1;
        break;
      case 'nutrient2':
        // eslint-disable-next-line i18next/no-literal-string
        goal_type = 'nutrient';
        change = targetChange.nutrient2;
        period = targetPeriod.nutrient2;
        target_name = targetName.nutrient2;
        target_value = targetValue.nutrient2;
        break;
      case 'nutrient3':
        // eslint-disable-next-line i18next/no-literal-string
        goal_type = 'nutrient';
        change = targetChange.nutrient3;
        period = targetPeriod.nutrient3;
        target_name = targetName.nutrient3;
        target_value = targetValue.nutrient3;
        break;
      case 'nutrient4':
        // eslint-disable-next-line i18next/no-literal-string
        goal_type = 'nutrient';
        change = targetChange.nutrient4;
        period = targetPeriod.nutrient4;
        target_name = targetName.nutrient4;
        target_value = targetValue.nutrient4;
        break;
      case 'nutrient5':
        // eslint-disable-next-line i18next/no-literal-string
        goal_type = 'nutrient';
        change = targetChange.nutrient5;
        period = targetPeriod.nutrient5;
        target_name = targetName.nutrient5;
        target_value = targetValue.nutrient5;
        break;
      case 'food':
        // eslint-disable-next-line i18next/no-literal-string
        goal_type = 'frequency';
        change = targetChange.food;
        period = targetPeriod.food;
        target_name = targetName.food;
        target_value = targetValue.food;
        break;
      default:
        addNotification([t('Error please complete all fields'), true]);
    }
    if (!goal_type || !period || !target_value || !target_name) {
      addNotification([t('Error please complete all fields'), true]);
      return;
    }

    saveRes.bind(
      goalService.addGoal({
        goal: {
          change: change as CreateGoalRequestChangeEnum,
          goal_type: goal_type as CreateGoalRequestGoalTypeEnum,
          period: period as CreateGoalRequestPeriodEnum,
          target_name,
          target_value: parseFloat(target_value),
        },
      }),
    );
    track(ANALYTICS_EVENTS.GOAL_ADDED, {
      'Patient ID': patient.patient_id,
      'Goal Type': goal_type,
    });
  };

  const handleEditGoal = async (goal_id, goal_type) => {
    if (goal_id == '') {
      return;
    }
    let change = '';
    let period = '';
    let target_name = '';
    let target_value = '';
    if (goal_type == 'nutrient') {
      change = targetChange.nutrient5;
      period = targetPeriod.nutrient5;
      target_name = targetName.nutrient5;
      target_value = targetValue.nutrient5;
    } else {
      change = targetChange.food;
      period = targetPeriod.food;
      target_name = targetName.food;
      target_value = targetValue.food;
    }
    if (!goal_type || !period || !target_value || !target_name || !change) {
      addNotification([t('Error please complete all fields'), true]);
      return;
    }
    saveRes.bind(
      goalService.updateGoal(
        {
          goal_id,
          goal: {
            goal_type,
            change,
            period: period as UpdateGoalRequestPeriodEnum,
            target_name,
            target_value: parseFloat(target_value),
          },
        },
      ),
    );
    track(ANALYTICS_EVENTS.GOAL_EDITED, {
      'Patient ID': patient.patient_id,
      'Goal ID': goal_id,
    });
  };

  const handleDeleteGoal = async (goalId) => {
    console.log('delete ' + goalId);
    saveRes.bind(goalService.deleteGoal({ goal_id: goalId }));
    track(ANALYTICS_EVENTS.GOAL_DELETED, {
      'Patient ID': patient.patient_id,
      'Goal ID': goalId,
    });
  };

  const resetGoalForm = () => {
    setEditMode(prevState => ({ ...prevState, nutrient: false, food: false, goal_id: '' }));
    setChange(prevState => ({
      ...prevState,
      nutrient1: '',
      nutrient2: '',
      nutrient3: '',
      nutrient4: '',
      nutrient5: '',
      food: '',
    }));
    setTargetName(prevState => ({
      ...prevState,
      nutrient1: 'carbohydrate_g',
      nutrient2: 'protein_g',
      nutrient3: 'fiber_g',
      nutrient4: 'energy_kcal',
      nutrient5: '',
      food: '',
    }));
    setTargetValue(prevState => ({
      ...prevState,
      nutrient1: '',
      nutrient2: '',
      nutrient3: '',
      nutrient4: '',
      nutrient5: '',
      food: '',
    }));
    setTargetPeriod(prevState => ({
      ...prevState,
      nutrient1: '',
      nutrient2: '',
      nutrient3: '',
      nutrient4: '',
      nutrient5: '',
      food: '',
    }));
  };
  if (saveRes.isDone) {
    queryClient.invalidateQueries({ queryKey: ['patient-goals'] });
    resetGoalForm();
    saveRes.clear();
  }

  const handleChange = (e, goal_type) => {
    switch (goal_type) {
      case 'nutrient1':
        setChange(prevState => ({ ...prevState, nutrient1: e.target.value }));
        break;
      case 'nutrient2':
        setChange(prevState => ({ ...prevState, nutrient2: e.target.value }));
        break;
      case 'nutrient3':
        setChange(prevState => ({ ...prevState, nutrient3: e.target.value }));
        break;
      case 'nutrient4':
        setChange(prevState => ({ ...prevState, nutrient4: e.target.value }));
        break;
      case 'nutrient5':
        setChange(prevState => ({ ...prevState, nutrient5: e.target.value }));
        break;
      case 'food':
        setChange(prevState => ({ ...prevState, food: e.target.value }));
    }
  };
  const handleNameChange = (e, goal_type) => {
    switch (goal_type) {
      case 'nutrient1':
        setTargetName(prevState => ({ ...prevState, nutrient1: e.target.value }));
        break;
      case 'nutrient2':
        setTargetName(prevState => ({ ...prevState, nutrient2: e.target.value }));
        break;
      case 'nutrient3':
        setTargetName(prevState => ({ ...prevState, nutrient3: e.target.value }));
        break;
      case 'nutrient4':
        setTargetName(prevState => ({ ...prevState, nutrient4: e.target.value }));
        break;
      case 'nutrient5':
        setTargetName(prevState => ({ ...prevState, nutrient5: e.target.value }));
        break;
      case 'food':
        setTargetName(prevState => ({ ...prevState, food: e.target.value }));
    }
  };
  const handleValueChange = (e, goal_type) => {
    // regex: value updates only if it contains only positive real numbers or empty string
    const regexNum = /^(?:\d{0,6}(?:\.\d{0,6})?)?$/;
    if (!regexNum.test(e.target.value)) {
      return;
    }
    switch (goal_type) {
      case 'nutrient1':
        setTargetValue(prevState => ({ ...prevState, nutrient1: e.target.value }));
        break;
      case 'nutrient2':
        setTargetValue(prevState => ({ ...prevState, nutrient2: e.target.value }));
        break;
      case 'nutrient3':
        setTargetValue(prevState => ({ ...prevState, nutrient3: e.target.value }));
        break;
      case 'nutrient4':
        setTargetValue(prevState => ({ ...prevState, nutrient4: e.target.value }));
        break;
      case 'nutrient5':
        setTargetValue(prevState => ({ ...prevState, nutrient5: e.target.value }));
        break;
      case 'food':
        setTargetValue(prevState => ({ ...prevState, food: e.target.value }));
    }
  };
  const handlePeriodChange = (e, goal_type) => {
    switch (goal_type) {
      case 'nutrient1':
        setTargetPeriod(prevState => ({ ...prevState, nutrient1: e.target.value }));
        break;
      case 'nutrient2':
        setTargetPeriod(prevState => ({ ...prevState, nutrient2: e.target.value }));
        break;
      case 'nutrient3':
        setTargetPeriod(prevState => ({ ...prevState, nutrient3: e.target.value }));
        break;
      case 'nutrient4':
        setTargetPeriod(prevState => ({ ...prevState, nutrient4: e.target.value }));
        break;
      case 'nutrient5':
        setTargetPeriod(prevState => ({ ...prevState, nutrient5: e.target.value }));
        break;
      case 'food':
        setTargetPeriod(prevState => ({ ...prevState, food: e.target.value }));
    }
  };

  const bindToGoalForm = (goal) => {
    if (goal.goal_type === 'nutrient') {
      setEditMode(prevState => ({ ...prevState, nutrient: true, goal_id: goal.goal_id }));
      setChange(prevState => ({ ...prevState, nutrient5: goal.change }));
      setTargetName(prevState => ({ ...prevState, nutrient5: goal.target_name }));
      setTargetValue(prevState => ({ ...prevState, nutrient5: goal.target_value }));
      setTargetPeriod(prevState => ({ ...prevState, nutrient5: goal.period }));
    } else if (goal.goal_type === 'frequency') {
      setEditMode(prevState => ({ ...prevState, food: true, goal_id: goal.goal_id }));
      setChange(prevState => ({ ...prevState, food: goal.change }));
      setTargetName(prevState => ({ ...prevState, food: goal.target_name }));
      setTargetValue(prevState => ({ ...prevState, food: goal.target_value }));
      setTargetPeriod(prevState => ({ ...prevState, food: goal.period }));
    }
  };

  const deleteInputHandler = (nutrient) => {
    switch (nutrient) {
      case 'nutrient1':
        setDeleted(prevState => ({ ...prevState, nutrient1: true }));
        break;
      case 'nutrient2':
        setDeleted(prevState => ({ ...prevState, nutrient2: true }));
        break;
      case 'nutrient3':
        setDeleted(prevState => ({ ...prevState, nutrient3: true }));
        break;
      case 'nutrient4':
        setDeleted(prevState => ({ ...prevState, nutrient4: true }));
        break;
    }
  };

  return (
    <>
      <div className="goalTypeTitle mt-4">
        <h4>
          <Trans>Nutrient-Based Prescriptions</Trans>
        </h4>
      </div>
      <Row className={classnames('goalsList row border-0 mb-5')}>
        <Col className={classnames('goalListTable')}>
          <table>
            <thead>
              <tr>
                <th style={{ width: '200px' }}>
                  <Trans>Nutrient</Trans>
                </th>
                <th style={{ width: '200px' }}>
                  <Trans>More/Less</Trans>
                </th>
                <th style={{ width: '100px' }}>
                  <Trans>Units</Trans>
                </th>
                <th style={{ width: '120px' }}>
                  <Trans>Times</Trans>
                </th>
                <th style={{ width: '150px' }}>
                  <Trans>Target</Trans>
                </th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            {goals.filter(goal => goal.goal_type == 'nutrient').map((goal, index) => {
              return (
                <tbody key={index}>
                  <tr>
                    <td style={{ width: '200px' }}>
                      {!goal.target_name?.startsWith('cc_')
                          && NUTRIENT_TABLE_ROWS.some((nutrient) => nutrient === goal.target_name)
                        ? (
                          <Link
                            to={`/patient/${idToStr(patient.patient_id)}/nutrients/?nutrient=${goal.target_name}`}
                            style={{ textDecoration: 'underline' }}
                          >
                            {getGoalTargetOptions(t).find(option => option.id === goal.target_name)?.labelTr}
                          </Link>
                        )
                        : getGoalTargetOptions(t).find(option => option.id === goal.target_name)?.labelTr}
                    </td>
                    <td style={{ width: '200px' }}>{renderGoalText(goal, t)}</td>
                    <td style={{ width: '100px' }}>{goal.target_value} {goal.target_unit}</td>
                    <td style={{ width: '120px' }}>{t('per')} {t(goal.period)}</td>
                    <td style={{ width: '300px' }}>
                      {goal.statement !== ''
                        ? (goal.statement.replace(
                          '. Talk to your clinician about personalized strategies to help you achieve your goal',
                          '',
                        ).replace('your', '').replace('You', ''))
                        : '-'}
                    </td>
                    <td>
                      <span
                        className="goalTypeButton mx-3"
                        onClick={() => {
                          bindToGoalForm(goal);
                        }}
                      >
                        <i className="fas fa-pen" />
                      </span>
                      <span
                        className="goalTypeButton delete mx-0"
                        onClick={() => {
                          if (
                            window.confirm(t('Are you sure you wish to delete this item?'))
                          ) {
                            handleDeleteGoal(goal.goal_id);
                          }
                        }}
                      >
                        <i className="fas fa-times-circle" />
                      </span>
                    </td>
                  </tr>
                </tbody>
              );
            })}
          </table>
        </Col>
        {saveRes.isPending && (
          <Col style={{ marginTop: '-10px', marginBottom: '20px' }}>
            <div style={{ width: '90%' }}>
              <div className="d-flex justify-content-center align-items-center">
                <i className="fas fa-spinner fa-spin" />
              </div>
            </div>
          </Col>
        )}
        <Col
          className={classnames('addGoalCol', {
            'd-none': editMode.nutrient !== false || currentGoalsMap.has('carbohydrate_g') || isDeleted.nutrient1,
          })}
        >
          {/* <div className="addGoalFor">Consume</div> */}
          <select
            onChange={(event) => {
              event.persist();
              handleNameChange(event, 'nutrient1');
            }}
            value={targetName.nutrient1}
            className="addGoalNutrient browser-default custom-select addGoalSelect"
          >
            <option value="" disabled>
              {t('Nutrient')}
            </option>
            {goalTargets
              .filter(item => (item.goal_type === 'nutrient'))
              .map((item, idx) => {
                return (
                  <option key={idx} value={item.target_name}>
                    {getGoalTargetOptions(t).find(option => option.id === item.target_name)?.labelTr}
                  </option>
                );
              })}
          </select>
          <select
            value={targetChange.nutrient1}
            onChange={(event) => {
              event.persist();
              handleChange(event, 'nutrient1');
            }}
            className="addGoalChange browser-default custom-select addGoalSelect"
          >
            <option value="" disabled>
              {t('More / less than')}
            </option>
            <option value="increase">{t('More than')}</option>
            <option value="decrease">{t('Less than')}</option>
          </select>
          <Input
            onChange={(event) => {
              event.persist();
              handleValueChange(event, 'nutrient1');
            }}
            value={targetValue.nutrient1}
            className="addGoalAmount"
            type="text"
            label={t('amount')}
            background
            size="lg"
          />
          <div className="addGoalOf">
            {targetName.nutrient1 != ''
              ? goalTargets.find(
                (nutrient) => nutrient.target_name == targetName.nutrient1,
              )?.target_unit
              : t('units')}
          </div>
          <select
            value={targetPeriod.nutrient1}
            onChange={(event) => {
              event.persist();
              handlePeriodChange(event, 'nutrient1');
            }}
            className="addGoalTime browser-default custom-select addGoalSelect"
          >
            <option value="" disabled>
              {t('Time')}
            </option>
            {nutrientTime.map((item, index) => {
              return (
                <option value={item[0]} key={index}>
                  {item[1]}
                </option>
              );
            })}
          </select>
          <div className="addGoalButton mx-3">
            <span
              className={isDisabled.nutrient1 ? 'goalTypeButtonDisabled' : 'goalTypeButton'}
              onClick={() => handleAddGoal('nutrient1')}
            >
              <i className="fas fa-check-circle" />
            </span>
          </div>
          <div className="addGoalButton mx-0">
            <span className="goalTypeButton delete" onClick={() => deleteInputHandler('nutrient1')}>
              <i className="fas fa-times-circle" />
            </span>
          </div>
        </Col>
        <Col
          className={classnames('addGoalCol', {
            'd-none': editMode.nutrient !== false || currentGoalsMap.has('protein_g') || isDeleted.nutrient2,
          })}
        >
          {/* <div className="addGoalFor">Consume</div> */}
          <select
            onChange={(event) => {
              event.persist();
              handleNameChange(event, 'nutrient2');
            }}
            value={targetName.nutrient2}
            className="addGoalNutrient browser-default custom-select addGoalSelect"
          >
            <option value="" disabled>
              {t('Nutrient')}
            </option>
            {goalTargets
              .filter(item => (item.goal_type === 'nutrient'))
              .map((item, idx) => {
                return (
                  <option key={idx} value={item.target_name}>
                    {getGoalTargetOptions(t).find(option => option.id === item.target_name)?.labelTr}
                  </option>
                );
              })}
          </select>
          <select
            value={targetChange.nutrient2}
            onChange={(event) => {
              event.persist();
              handleChange(event, 'nutrient2');
            }}
            className="addGoalChange browser-default custom-select addGoalSelect"
          >
            <option value="" disabled>
              {t('More / less than')}
            </option>
            <option value="increase">{t('More than')}</option>
            <option value="decrease">{t('Less than')}</option>
          </select>
          <Input
            onChange={(event) => {
              event.persist();
              handleValueChange(event, 'nutrient2');
            }}
            value={targetValue.nutrient2}
            className="addGoalAmount"
            type="text"
            label={t('amount')}
            background
            size="lg"
          />
          <div className="addGoalOf">
            {targetName.nutrient2 != ''
              ? goalTargets.find(
                (nutrient) => nutrient.target_name == targetName.nutrient2,
              )?.target_unit
              : t('units')}
          </div>
          <select
            value={targetPeriod.nutrient2}
            onChange={(event) => {
              event.persist();
              handlePeriodChange(event, 'nutrient2');
            }}
            className="addGoalTime browser-default custom-select addGoalSelect"
          >
            <option value="" disabled>
              {t('Time')}
            </option>
            {nutrientTime.map((item, index) => {
              return (
                <option value={item[0]} key={index}>
                  {item[1]}
                </option>
              );
            })}
          </select>
          <div className="addGoalButton mx-3">
            <span
              className={isDisabled.nutrient2 ? 'goalTypeButtonDisabled' : 'goalTypeButton'}
              onClick={() => handleAddGoal('nutrient2')}
            >
              <i className="fas fa-check-circle" />
            </span>
          </div>
          <div className="addGoalButton mx-0">
            <span className="goalTypeButton delete" onClick={() => deleteInputHandler('nutrient2')}>
              <i className="fas fa-times-circle" />
            </span>
          </div>
        </Col>
        <Col
          className={classnames('addGoalCol', {
            'd-none': editMode.nutrient !== false || currentGoalsMap.has('fiber_g') || isDeleted.nutrient3,
          })}
        >
          {/* <div className="addGoalFor">Consume</div> */}
          <select
            onChange={(event) => {
              event.persist();
              handleNameChange(event, 'nutrient3');
            }}
            value={targetName.nutrient3}
            className="addGoalNutrient browser-default custom-select addGoalSelect"
          >
            <option value="" disabled>
              {t('Nutrient')}
            </option>
            {goalTargets
              .filter(item => (item.goal_type === 'nutrient'))
              .map((item, idx) => {
                return (
                  <option key={idx} value={item.target_name}>
                    {getGoalTargetOptions(t).find(option => option.id === item.target_name)?.labelTr}
                  </option>
                );
              })}
          </select>
          <select
            value={targetChange.nutrient3}
            onChange={(event) => {
              event.persist();
              handleChange(event, 'nutrient3');
            }}
            className="addGoalChange browser-default custom-select addGoalSelect"
          >
            <option value="" disabled>
              {t('More / less than')}
            </option>
            <option value="increase">{t('More than')}</option>
            <option value="decrease">{t('Less than')}</option>
          </select>
          <Input
            onChange={(event) => {
              event.persist();
              handleValueChange(event, 'nutrient3');
            }}
            value={targetValue.nutrient3}
            className="addGoalAmount"
            type="text"
            label={t('amount')}
            background
            size="lg"
          />
          <div className="addGoalOf">
            {targetName.nutrient3 != ''
              ? goalTargets.find(
                (nutrient) => nutrient.target_name == targetName.nutrient3,
              )?.target_unit
              : t('units')}
          </div>
          <select
            value={targetPeriod.nutrient3}
            onChange={(event) => {
              event.persist();
              handlePeriodChange(event, 'nutrient3');
            }}
            className="addGoalTime browser-default custom-select addGoalSelect"
          >
            <option value="" disabled>
              {t('Time')}
            </option>
            {nutrientTime.map((item, index) => {
              return (
                <option value={item[0]} key={index}>
                  {item[1]}
                </option>
              );
            })}
          </select>
          <div className="addGoalButton mx-3">
            <span
              className={isDisabled.nutrient3 ? 'goalTypeButtonDisabled' : 'goalTypeButton'}
              onClick={() => handleAddGoal('nutrient3')}
            >
              <i className="fas fa-check-circle" />
            </span>
          </div>
          <div className="addGoalButton mx-0">
            <span className="goalTypeButton delete" onClick={() => deleteInputHandler('nutrient3')}>
              <i className="fas fa-times-circle" />
            </span>
          </div>
        </Col>
        <Col
          className={classnames('addGoalCol', {
            'd-none': editMode.nutrient !== false || currentGoalsMap.has('energy_kcal') || isDeleted.nutrient4,
          })}
        >
          {/* <div className="addGoalFor">Consume</div> */}
          <select
            onChange={(event) => {
              event.persist();
              handleNameChange(event, 'nutrient4');
            }}
            value={targetName.nutrient4}
            className="addGoalNutrient browser-default custom-select addGoalSelect"
          >
            <option value="" disabled>
              {t('Nutrient')}
            </option>
            {goalTargets
              .filter(item => (item.goal_type === 'nutrient'))
              .map((item, idx) => {
                return (
                  <option key={idx} value={item.target_name}>
                    {getGoalTargetOptions(t).find(option => option.id === item.target_name)?.labelTr}
                  </option>
                );
              })}
          </select>
          <select
            value={targetChange.nutrient4}
            onChange={(event) => {
              event.persist();
              handleChange(event, 'nutrient4');
            }}
            className="addGoalChange browser-default custom-select addGoalSelect"
          >
            <option value="" disabled>
              {t('More / less than')}
            </option>
            <option value="increase">{t('More than')}</option>
            <option value="decrease">{t('Less than')}</option>
          </select>
          <Input
            onChange={(event) => {
              event.persist();
              handleValueChange(event, 'nutrient4');
            }}
            value={targetValue.nutrient4}
            className="addGoalAmount"
            type="text"
            label={t('amount')}
            background
            size="lg"
          />
          <div className="addGoalOf">
            {targetName.nutrient4 != ''
              ? goalTargets.find(
                (nutrient) => nutrient.target_name == targetName.nutrient4,
              )?.target_unit
              : t('units')}
          </div>
          <select
            value={targetPeriod.nutrient4}
            onChange={(event) => {
              event.persist();
              handlePeriodChange(event, 'nutrient4');
            }}
            className="addGoalTime browser-default custom-select addGoalSelect"
          >
            <option value="" disabled>
              {t('Time')}
            </option>
            {nutrientTime.map((item, index) => {
              return (
                <option value={item[0]} key={index}>
                  {item[1]}
                </option>
              );
            })}
          </select>
          <div className="addGoalButton mx-3">
            <span
              className={isDisabled.nutrient4 ? 'goalTypeButtonDisabled' : 'goalTypeButton'}
              onClick={() => {
                handleAddGoal('nutrient4');
              }}
            >
              <i className="fas fa-check-circle" />
            </span>
          </div>
          <div className="addGoalButton mx-0">
            <span className="goalTypeButton delete" onClick={() => deleteInputHandler('nutrient4')}>
              <i className="fas fa-times-circle" />
            </span>
          </div>
        </Col>
        <Col
          className={classnames('addGoalCol', {
            'd-none': isDeleted.nutrient5,
          })}
        >
          {/* <div className="addGoalFor">Consume</div> */}
          <select
            onChange={(event) => {
              event.persist();
              handleNameChange(event, 'nutrient5');
            }}
            value={targetName.nutrient5}
            className="addGoalNutrient browser-default custom-select addGoalSelect"
          >
            <option value="" disabled>
              {t('Nutrient')}
            </option>
            {goalTargets
              .filter(item => (item.goal_type === 'nutrient'))
              .map((item, idx) => {
                return (
                  <option key={idx} value={item.target_name}>
                    {getGoalTargetOptions(t).find(option => option.id === item.target_name)?.labelTr}
                  </option>
                );
              })}
          </select>
          <select
            value={targetChange.nutrient5}
            onChange={(event) => {
              event.persist();
              handleChange(event, 'nutrient5');
            }}
            className="addGoalChange browser-default custom-select addGoalSelect"
          >
            <option value="" disabled>
              {t('More / less than')}
            </option>
            <option value="increase">{t('More than')}</option>
            <option value="decrease">{t('Less than')}</option>
          </select>
          <Input
            onChange={(event) => {
              event.persist();
              handleValueChange(event, 'nutrient5');
            }}
            value={targetValue.nutrient5}
            className="addGoalAmount"
            type="text"
            label={t('amount')}
            background
            size="lg"
          />
          <div className="addGoalOf">
            {targetName.nutrient5 != ''
              ? goalTargets.find(
                (nutrient) => nutrient.target_name == targetName.nutrient5,
              )?.target_unit
              : t('units')}
          </div>
          <select
            value={targetPeriod.nutrient5}
            onChange={(event) => {
              event.persist();
              handlePeriodChange(event, 'nutrient5');
            }}
            className="addGoalTime browser-default custom-select addGoalSelect"
          >
            <option value="" disabled>
              {t('Time')}
            </option>
            {nutrientTime.map((item, index) => {
              return (
                <option value={item[0]} key={index}>
                  {item[1]}
                </option>
              );
            })}
          </select>
          <div className={classnames('addGoalButton mx-3', { 'd-none': editMode.nutrient !== false })}>
            <span
              className={isDisabled.nutrient5 ? 'goalTypeButtonDisabled' : 'goalTypeButton'}
              onClick={() => {
                handleAddGoal('nutrient5');
              }}
            >
              <i className="fas fa-check-circle" />
            </span>
          </div>

          <div className={editMode.nutrient !== true ? 'd-none' : 'd-flex'}>
            <div className="addGoalButton mx-3">
              <span
                className={isDisabled.nutrient5 ? 'goalTypeButtonDisabled' : 'goalTypeButton'}
                onClick={() => handleEditGoal(editMode.goal_id, 'nutrient')}
              >
                <i className="fas fa-check-circle" />
              </span>
            </div>
            <div className="addGoalButton mx-0">
              <span
                className="goalTypeButton delete"
                onClick={() => {
                  resetGoalForm();
                  setEditMode((prevState) => ({ ...prevState, nutrient: false }));
                }}
              >
                <i className="fas fa-times-circle" />
              </span>
            </div>
          </div>
        </Col>
      </Row>
      <div className="goalTypeTitle">
        <h4>
          <Trans>Food-Based Prescriptions</Trans>
        </h4>
      </div>
      <Row className={classnames('goalsList row border-0 mb-5')}>
        <Col className={classnames('goalListTable')}>
          <table>
            <thead>
              <tr>
                <th style={{ width: '200px' }}>
                  <Trans>Food</Trans>
                </th>
                <th style={{ width: '200px' }}>
                  <Trans>Increase / Decrease</Trans>
                </th>
                <th style={{ width: '100px' }}>
                  <Trans>Amount</Trans>
                </th>
                <th style={{ width: '120px' }}>
                  <Trans>Times</Trans>
                </th>
                <th style={{ width: '150px' }}>
                  <Trans>Target</Trans>
                </th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            {goals.filter(goal => goal.goal_type == 'frequency').map((goal, index) => {
              return (
                <tbody key={index}>
                  <tr>
                    <td style={{ width: '200px' }}>
                      {getGoalTargetOptions(t).find(option => option.id === goal.target_name)?.labelTr}
                    </td>
                    <td style={{ width: '200px' }}>{renderGoalText(goal, t)}</td>
                    <td style={{ width: '100px' }}>{goal.target_value} {goal.target_unit}</td>
                    <td style={{ width: '120px' }}>{t('every')} {t(goal.period)}</td>
                    <td style={{ width: '300px' }}>
                      {goal.statement !== ''
                        ? (goal.statement.replace(
                          '. Talk to your clinician about personalized strategies to help you achieve your goal',
                          '',
                        ).replace('your', '').replace('You', ''))
                        : '-'}
                    </td>
                    <td>
                      <span
                        className="goalTypeButton mx-3"
                        onClick={() => {
                          bindToGoalForm(goal);
                        }}
                      >
                        <i className="fas fa-pen" />
                      </span>
                      <span
                        className="goalTypeButton delete mx-0"
                        onClick={() => {
                          if (
                            window.confirm(t('Are you sure you wish to delete this item?'))
                          ) {
                            handleDeleteGoal(goal.goal_id);
                          }
                        }}
                      >
                        <i className="fas fa-times-circle" />
                      </span>
                    </td>
                  </tr>
                </tbody>
              );
            })}
          </table>
        </Col>
        {saveRes.isPending && (
          <Col style={{ marginTop: '-10px', marginBottom: '20px' }}>
            <div className="d-flex justify-content-center align-items-center">
              <i className="fas fa-spinner fa-spin" />
            </div>
          </Col>
        )}
        <Col className="addGoalCol">
          <select
            onChange={(event) => {
              event.persist();
              handleChange(event, 'food');
            }}
            value={targetChange.food}
            className="addGoalChange browser-default custom-select addGoalSelect"
          >
            <option value="" disabled>
              {t('Increase / Decrease')}
            </option>
            <option value="increase">{t('Increase')}</option>
            <option value="decrease">{t('Decrease')}</option>
          </select>
          <select
            onChange={(event) => {
              event.persist();
              handleNameChange(event, 'food');
            }}
            value={targetName.food}
            className="addGoalNutrient browser-default custom-select addGoalSelect"
          >
            <option value="" disabled>
              {t('Food')}
            </option>
            {goalTargets
              .filter(item => (item.goal_type === 'frequency'))
              .map((item, idx) => {
                return (
                  <option key={idx} value={item.target_name}>
                    {getGoalTargetOptions(t).find(option => option.id === item.target_name)?.labelTr}
                  </option>
                );
              })}
          </select>
          <div className="mr-4">{t('to')}</div>
          <Input
            onChange={(event) => {
              event.persist();
              handleValueChange(event, 'food');
            }}
            value={targetValue.food}
            className="addGoalAmount"
            type="text"
            label={t('amount')}
            background
            size="lg"
          />
          <div className="addGoalOf">{t('times')}</div>
          <select
            value={targetPeriod.food}
            onChange={(event) => {
              event.persist();
              handlePeriodChange(event, 'food');
            }}
            className="addGoalTime browser-default custom-select addGoalSelect"
          >
            <option value="" disabled>
              {t('Time')}
            </option>
            {frequencyTime.map((item, index) => {
              return (
                <option key={index} value={item[0]}>
                  {item[1]}
                </option>
              );
            })}
          </select>
          <div className={classnames('addGoalButton mx-3', { 'd-none': editMode.food !== false })}>
            <span
              className={isDisabled.food ? 'goalTypeButtonDisabled' : 'goalTypeButton'}
              onClick={() => handleAddGoal('food')}
            >
              <i className="fas fa-check-circle" />
            </span>
          </div>
          <div className={classnames('addGoalButton mx-0', { 'd-none': editMode.food !== true })}>
            <span
              className={isDisabled.food ? 'goalTypeButtonDisabled' : 'goalTypeButton'}
              onClick={() => handleEditGoal(editMode.goal_id, 'frequency')}
            >
              <i className="fas fa-check-circle" />
            </span>
          </div>
        </Col>
      </Row>
    </>
  );
};
