import { useMutation, useQuery } from '@tanstack/react-query';
import _, { flatten } from 'lodash';
import { DateTime } from 'luxon';
import { MDBContainer, MDBModal, MDBModalBody, MDBModalHeader } from 'mdbreact';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { ANALYTICS_EVENTS, track } from '../analytics';
import { mealApi, reportingApi, subscriptionApi } from '../api';
import { MealResponse, PatientReportResponse, PatientSubscriptionResponse } from '../api/generated/models';
import { useStore, useStoreDispatch } from '../context';
import { Trans, useTranslation } from '../i18n';
import { ActivityFeedItem, usePatientActivityFeed } from '../pages/patient-logV2';
import { ClinicService } from '../services/clinic';
import { PatientService } from '../services/patient';
import { formatDate } from '../utils/formatDate';
import { DateListSelector, FilterType, getEnumeratedDays, RangePickerWithActivityIndicators } from './foodFilterNew';
import { flattenFeedEntries, getMealResponseByMealId } from './helpers/mealHelpers';
import { ShowMealV2 } from './showMealV2';
import { useCalendarActivityIndicators } from './useCalendarActivityFeed';

const getDefaultDateRange = (defaultSub: any): [moment.Moment, moment.Moment] => {
  if (defaultSub) {
    let [start, end] = [
      moment(defaultSub.start_date).startOf('day'),
      moment(defaultSub.expiration_date).endOf('day'),
    ];
    if (start > moment()) {
      start = moment().startOf('day');
    }
    if (end > moment()) {
      end = moment().endOf('day');
    }
    return [start, end];
  }
  return [moment().subtract(7, 'd').startOf('day'), moment().endOf('day')];
};

const isFeedEntriesInRange = (
  feedEntries: ActivityFeedItem[],
  startDate: moment.Moment,
  endDate: moment.Moment,
  excludeDates: string[],
) => {
  return feedEntries
    .filter(item => item.type === 'meal-log')
    .some((item) => {
      const timestamp = moment(item.timestamp.toJSDate());
      // eslint-disable-next-line i18next/no-literal-string
      if (excludeDates.includes(timestamp.format('YYYY-MM-DD'))) {
        return false;
      }
      const dateRange = timestamp.isSameOrBefore(endDate.clone().endOf('day'))
        && timestamp.isSameOrAfter(startDate.clone().startOf('day'));
      return !!dateRange;
    });
};

export const ErrorFlagsModal = (props: {
  show: boolean,
  toggle: () => void,
  report: PatientReportResponse,
  errorMessageRegex: RegExp,
  fetchReports: () => void,
}) => {
  const { t } = useTranslation();
  const [showMeal, setShowMeal] = useState<MealResponse>(null);

  const mealTimeline = usePatientActivityFeed({
    patientId: props.report.patient_id,
    activeDate: DateTime.fromISO(props.report.date_until),
  });

  const errors = props.report.error_flags
    .filter(flag => flag.message.match(props.errorMessageRegex))
    .map(flag => {
      // eslint-disable-next-line i18next/no-literal-string
      if (flag.message.includes('Meal ID')) {
        // eslint-disable-next-line i18next/no-literal-string
        const message = flag.message
          .slice(0, flag.message.indexOf('Meal ID')).trim()
          .replace('that is awaiting verification', 'currently being processed');
        const formattedMessage = message.endsWith('.') ? message.slice(0, -1) : message;
        const mealIdMatch = flag.message.match(/Meal ID:\s*(\d+)/);
        const mealId = mealIdMatch ? mealIdMatch[1] : null;

        return {
          mealId,
          message: formattedMessage,
          mealResponse: mealId
            ? getMealResponseByMealId(Number(mealId), flattenFeedEntries(mealTimeline.feedEntries))
            : null,
          // mealItemId: flag.fields[1].value,
          // foodName: flag.fields[0].value,
        };
      }
      return null;
    })
    .filter((flag, idx, self) => idx === self.findIndex(f => f.mealId === flag.mealId && f.message === flag.message))
    .sort((a, b) => DateTime.fromISO(a.mealResponse?.meal_date) > DateTime.fromISO(b.mealResponse?.meal_date) ? 1 : -1);

  const putDraftMutation = useMutation({
    mutationFn: async () => {
      const res = await reportingApi.appApiPatientReportsApiPutFoodReport({
        patient_id: props.report.patient_id,
        clinic_id: props.report.clinic_id,
        hospital_id: props.report.hospital_id,
        patient_report_id: props.report.patient_report_id,
        status: 'sent',
      });
      return res;
    },
    onError: (error) => {
      console.log(error);
      window.confirm(t('Failed to send report'));
    },
    onSuccess: () => {
      props.fetchReports();
      props.toggle();
    },
  });

  const approveReportMutation = useMutation({
    mutationFn: async () => {
      const res = await reportingApi.appApiPatientReportsApiPostApproveFoodReport({
        patient_id: props.report.patient_id,
        clinic_id: props.report.clinic_id,
        hospital_id: props.report.hospital_id,
        patient_report_id: props.report.patient_report_id,
      });
      return res;
    },
    onError: (error) => {
      console.log(error);
      window.confirm(t('Failed to approve report'));
    },
    onSuccess: () => putDraftMutation.mutate(),
  });

  return (
    <>
      <MDBContainer>
        <MDBModal backdrop={false} isOpen={props.show} centered size="lg">
          <MDBModalHeader toggle={props.toggle}>
            <i className="fas fa-file-alt margin-right-s" /> {t('Action Required: Review & Send Report')}
          </MDBModalHeader>
          <MDBModalBody>
            <Container>
              <Row>
                <Col className="mb-3">
                  <h6>
                    <Trans>
                      Sending the report finalizes the report and sends a notification to the clinician and/or patient
                      (if permitted). Review the following meals:
                    </Trans>
                  </h6>
                </Col>
              </Row>
              <Row className="mb-4">
                <Col className="mb-5">
                  {errors.map((err, idx) => (
                    <div key={idx} style={{ display: 'flex', gap: 4 }}>
                      {err.mealResponse && <span>{formatDate(err.mealResponse.meal_date) + ' –'}</span>}
                      <span>{t(err.message)}</span>
                      {err.mealResponse
                        && (
                          <span
                            style={{ textDecoration: 'underline', cursor: 'pointer', marginLeft: 5 }}
                            onClick={() => setShowMeal(err.mealResponse)}
                          >
                            <Trans>
                              show
                            </Trans>
                          </span>
                        )}
                      {
                        /* <span
                        style={{ textDecoration: 'underline', cursor: 'pointer' }}
                        // open meal timeline in new tab
                        onClick={() => window.open(`/patient-logV2?mealId=${err.mealId}`)}
                      >
                        <Trans>
                          view meal item in food log
                        </Trans>
                      </span> */
                      }
                    </div>
                  ))}
                </Col>
              </Row>

              <Row>
                <Col className="mb-2">
                  <Button block onClick={() => approveReportMutation.mutate()}>
                    {t('Send draft report')}
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col className="mb-2">
                  <Button block onClick={props.toggle}>
                    {t('Cancel')}
                  </Button>
                </Col>
              </Row>
            </Container>
          </MDBModalBody>
        </MDBModal>
      </MDBContainer>

      {!!showMeal && (
        <ShowMealV2
          selectedMeal={showMeal}
          activityData={mealTimeline.feedEntries.flatMap(section => section.data)}
          onHide={() => setShowMeal(null)}
          initialMode="view"
        />
      )}
    </>
  );
};

const GenerateReport = (props: {
  toggle: () => void,
  show: boolean,
  draftPending: boolean,
  setDraftPending: (draftPending: boolean) => void,
}) => {
  const { toggle, show, setDraftPending } = props;
  const { clinician, patient, allReports } = useStore();
  const { setAllPatientReports } = useStoreDispatch();
  const clinicService = ClinicService();
  const patientService = PatientService();
  const [dateError, setDateError] = useState('');
  const { t } = useTranslation();

  const mealDatesQuery = useQuery(['meals-since-last-report', patient.patient_id], async () => {
    const res = await mealApi.appApiMealGetMealsSinceLastReport({ patient_id: patient.patient_id });
    return res.data;
  });
  const mealDates = (mealDatesQuery?.data as unknown) as { meal_dates: string[] };

  const patientSubscriptionsQuery = useQuery(['patient-subscriptions', patient.patient_id], async () => {
    const res = await subscriptionApi.appApiSubscriptionApiGetPatientSubscriptions({ patient_id: patient.patient_id });
    return res.data || [];
  });
  const patientSubscriptions = patientSubscriptionsQuery.data;
  // default to most recent sub which started in the past, if there are no subs which started in the past use the next one that will start
  const subsPatientSorted = patientSubscriptions
    ?.filter(s => s.patient_id == patient.patient_id)
    ?.sort((a, b) => moment(a.start_date) > moment(b.start_date) ? -1 : 1);
  const defaultSub = !subsPatientSorted?.length
    ? null
    : moment(subsPatientSorted[subsPatientSorted.length - 1].start_date) < moment()
    ? subsPatientSorted.filter(s => moment(s.start_date) < moment()).slice(0, 1)[0]
    : subsPatientSorted[subsPatientSorted.length - 1];

  const [reportSub, setReportSub] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [comparisonReportId, setComparisonReportId] = useState(null);
  const [confirming, setConfirming] = useState(false);
  const [mealDateClickCount, setMealDateClickCount] = useState(0);

  const reset = () => {
    setReportSub(null);
    setStartDate(null);
    setEndDate(null);
  };

  const [filter, setFilter] = useState<FilterType>({
    rangePickerValue: [moment().subtract(7, 'd').startOf('day'), moment().endOf('day')],
    dateRanges: [],
  });
  const [checkboxes, setCheckboxes] = React.useState<{ [date: string]: boolean }>();
  const [dates, setDates] = useState({
    oldestDate: DateTime.local().minus({ days: 65 }),
    newestDate: null,
  });
  const { calendarActivityIndicators, newestDate, isFetching, feedEntries } = useCalendarActivityIndicators(dates);

  const [initialFilter, setInitialFilter] = useState(false);

  useEffect(() => {
    if (newestDate && !initialFilter) {
      const date = moment(newestDate?.toJSDate());
      const endDate = date.clone();
      const startDate = endDate.clone().add(-7, 'd');
      // onRangeChange(
      //   [startDate, endDate],
      //   [startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD')],
      // );
      setFilter({ rangePickerValue: [startDate, endDate], dateRanges: [] });
      setInitialFilter(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newestDate, initialFilter, isFetching]);

  const enumeratedDays = React.useMemo(() => getEnumeratedDays(filter.rangePickerValue, setCheckboxes), [
    filter.rangePickerValue,
  ]);

  const maxNumDays = 30;
  const [excludedDates, setExcludedDates] = useState<string[]>([]);
  const [numDaysSelected, setNumDaysSelected] = useState(0);

  const setReportSubscription = (sub_id) => {
    if (sub_id === reportSub) {
      setReportSub(null);
      return;
    }
    setReportSub(sub_id);

    const sub = patientSubscriptions.find(s => s.id == sub_id);
    console.log(sub);

    setFilter({
      rangePickerValue: getDefaultDateRange(sub),
      dateRanges: [],
    });
  };

  const handleComparisonId = (e) => {
    setComparisonReportId(e.target.value);
  };

  const clinicianReq = useQuery(['clinician-req'], async () => {
    return clinicService.getClinician();
  });
  const clinicReq = useQuery(['patient-clinics-req', patient.patient_id], async () => {
    return patientService.getPatientClinics(patient.patient_id);
  });

  useEffect(() => {
    if (!isFeedEntriesInRange(feedEntries, filter.rangePickerValue[0], filter.rangePickerValue[1], excludedDates)) {
      setDateError(t('Please select a date range that includes at least one meal entry.'));
    } else {
      setDateError('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter.rangePickerValue, feedEntries, excludedDates.length]);

  // find the clinic that is shared between the clinician and the patient
  const clinic = clinicReq.data
    && clinicianReq.data?.clinics?.find(c => clinicReq.data?.find(c2 => c2.id == c.id));

  const generateReport = async (dateSince: string, dateUntil: string, isDraft: boolean) => {
    if (!clinic) {
      return;
    }
    const response = await patientService.postFoodReport(
      patient?.patient_id ?? 0,
      clinic?.hospital_id ?? 0,
      clinic?.id ?? 0,
      dateSince,
      dateUntil,
      excludedDates,
      // eslint-disable-next-line i18next/no-literal-string
      isDraft ? 'draft' : 'sent',
      comparisonReportId,
      reportSub,
    );
    if (!response) {
      setDraftPending(false);
      return;
    }
    const p_clinics = clinicReq?.data.map((pid) => {
      if (clinician.clinics.filter(id => id == pid.id)) {
        return [pid.id, pid.hospital_id];
      }
      return [null, null];
    });
    const allReports: PatientReportResponse[] = [];
    await Promise.all(p_clinics?.map(async (p) => {
      if (!p[0]) {
        return;
      }
      const reports = await patientService.getAllPatientReports(patient.patient_id, p[1], p[0]);
      if (!reports) {
        return;
      }
      allReports.push(...reports);
      allReports.sort((a, b) => {
        return moment(b.created_time).diff(moment(a.created_time));
      });
    }));
    setAllPatientReports(allReports.concat());
    setDraftPending(false);
  };

  const handleGenerateReport = (isDraft: boolean) => {
    if (!isFeedEntriesInRange(feedEntries, filter.rangePickerValue[0], filter.rangePickerValue[1], excludedDates)) {
      return;
    }

    if (!isDraft && !confirming) { // if generate and send is being used and haven't confirmed yet, set confirming state true and exit call for now
      setConfirming(true);
      return;
    }
    setDraftPending(true);

    // eslint-disable-next-line i18next/no-literal-string
    const [dateSince, dateUntil] = filter.rangePickerValue.map(d => d.toISOString().split('T')[0]);

    generateReport(dateSince, dateUntil, isDraft);
    console.log('registered report using subscription id: ' + reportSub);
    track(ANALYTICS_EVENTS.DRAFT_REPORT_GENERATED, {
      'Patient ID': patient.patient_id,
      'Report Start Date': dateSince,
      'Report End Date': dateUntil,
    });
    reset();
    toggle();
  };

  const onMealDateClick = (date: string) => {
    if (!startDate || date < startDate) {
      setStartDate(date);
      setEndDate(null);
      setMealDateClickCount(1);
      return;
    }

    setMealDateClickCount(mealDateClickCount + 1);
    if (mealDateClickCount % 2 == 0) {
      setStartDate(date);
      setEndDate(null);
    } else {
      setEndDate(date);
      setFilter({ rangePickerValue: [moment(startDate).startOf('day'), moment(date).endOf('day')], dateRanges: [] });
    }
  };

  return (
    <MDBContainer>
      <MDBModal backdrop={false} isOpen={show} toggle={toggle} centered size="lg">
        <MDBModalHeader toggle={toggle}>
          <i className="fas fa-file-alt margin-right-s" /> {t('Generate New Report')}
        </MDBModalHeader>
        <MDBModalBody>
          <Container>
            <Row>
              <Col>
                <h6>
                  {t('Select a previous tracking period for {{patientFirstName}} {{patientLastName}}', {
                    patientFirstName: patient.first_name,
                    patientLastName: patient.last_name,
                  })}
                </h6>
                {(subsPatientSorted && subsPatientSorted?.length)
                  ? subsPatientSorted.map(sub => {
                    if (sub.patient_id == patient.patient_id && moment(sub.start_date) < moment()) {
                      const start = (moment(sub.start_date).toDate().getFullYear()
                          == moment(sub.expiration_date).toDate().getFullYear())
                        ? moment(sub.start_date).format('MMM DD')
                        : moment(sub.start_date).format('MMM DD/YYYY');
                      const date = start.concat(' - ', moment(sub.expiration_date).format('MMM DD/YYYY'));
                      return (
                        <div key={sub.id} onClick={() => setReportSubscription(sub.id)} style={{ maxWidth: 200 }}>
                          {sub.id === reportSub
                            ? (
                              <label style={{ cursor: 'pointer' }}>
                                <mark>{date}</mark>
                              </label>
                            )
                            : <label style={{ cursor: 'pointer' }}>{date}</label>}
                          <span className="goalTypeButton">
                            <i className="fas fa-plus-circle" />
                          </span>
                        </div>
                      );
                    }
                  })
                  : t('none')}
              </Col>
            </Row>
            <br />
            <Row>
              <Col>
                <h6>
                  <Trans>Meal Dates Since Last Report:</Trans>
                </h6>
                <div className="mealDateDisplay">
                  {mealDates?.meal_dates && [...mealDates.meal_dates].reverse().map((date, idx) => {
                    return (
                      <div
                        key={idx}
                        style={{
                          cursor: 'pointer',
                          fontWeight: (
                            startDate && !endDate && date == startDate
                              ? 'bold'
                              : startDate && endDate && date >= startDate && date <= endDate
                              ? 'bold'
                              : 'normal'
                          ),
                        }}
                        onClick={() => onMealDateClick(date)}
                      >
                        {date}
                      </div>
                    );
                  })}
                </div>
              </Col>
              <Col>
                <h6>
                  <Trans>Select Comparison Report:</Trans>
                </h6>
                <Form.Control value={comparisonReportId || ''} as="select" onChange={handleComparisonId}>
                  <option value={null}>{t('No Comparison Report')}</option>
                  {/* eslint-disable-next-line i18next/no-literal-string */}
                  {[...allReports]
                    .filter(r => moment(r.date_since).isSameOrAfter('2022-01-01', 'year'))
                    .reverse().map(
                      (item, idx) => {
                        return (
                          <option key={idx} value={item.patient_report_id}>
                            {moment(item.date_since).format('MMM DD/YYYY')} -{' '}
                            {moment(item.date_until).format('MMM DD/YYYY')}
                          </option>
                        );
                      },
                    )}
                </Form.Control>
              </Col>
            </Row>
            <br />
            <Row>
              <Col className="mb-2">
                <h6 style={{ display: 'inline' }}>
                  <Trans>Customize Date Range</Trans>
                </h6>
                <span style={{ fontSize: '15px' }}>
                  {' '}
                  (<Trans>A maximum of 30 days can be selected</Trans>):
                </span>
              </Col>
            </Row>
            <Row>
              <Col>
                <RangePickerWithActivityIndicators
                  calendarActivityIndicators={calendarActivityIndicators}
                  isFetching={isFetching}
                  checkboxes={checkboxes}
                  filter={filter}
                  dates={dates}
                  setFilter={setFilter}
                  setDates={setDates}
                  maxMonths="one"
                  maxNumDays={maxNumDays}
                  setNumDaysSelected={setNumDaysSelected}
                  setExcludedDates={setExcludedDates}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col>
                <h6>
                  <Trans>Remove Dates from Range:</Trans>
                </h6>
              </Col>
            </Row>
            <Row>
              <Col style={{ height: '200px' }}>
                <DateListSelector
                  enumeratedDays={enumeratedDays}
                  feedEntries={feedEntries}
                  checkboxes={checkboxes}
                  setCheckboxes={setCheckboxes}
                  listWidth={700}
                  isReportsModal={true}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <br />
                <div style={{ fontSize: '15px' }}>
                  <div style={{ marginBottom: '10px' }} className="errorMessage errorLabel">{t(dateError)}</div>
                  {t(
                    'A report will be generated for {{firstName}} {{lastName}} for the period {{dateRange}}',
                    {
                      firstName: patient.first_name,
                      lastName: patient.last_name,
                      dateRange: moment(filter.rangePickerValue[0]).format('MMM DD, YYYY')
                        + ' – ' + moment(filter.rangePickerValue[1]).format('MMM DD, YYYY'),
                    },
                  )}
                  {!!excludedDates.length && (
                    <span>
                      {t(', excluding the following dates: ')}
                      {excludedDates.map((date, idx) => {
                        return (
                          <span key={idx}>
                            {moment(date).format('MMM DD, YYYY')}
                            {idx < excludedDates.length - 1 ? ', ' : '.'}
                          </span>
                        );
                      })}
                    </span>
                  )}
                  <span>
                    {' ' + t('({{numDaysSelected}} days).', { numDaysSelected })}
                  </span>
                </div>
                <br />
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="margin-bot-l">
                  <Row>
                    {(clinician.is_report_admin || clinician?.flags?.clinician_can_generate_reports) && (
                      clinicReq.isError || clinicianReq.isError || !clinic
                        ? (
                          <Col>
                            <div className="errorMessage errorLabel">
                              <Trans>Error loading patient data</Trans>
                            </div>
                          </Col>
                        )
                        : (
                          <Col>
                            <Button block disabled={!!dateError} onClick={() => handleGenerateReport(true)}>
                              {t('Generate Draft')}
                            </Button>
                          </Col>
                        )
                    )}
                    <br />
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </MDBModalBody>
      </MDBModal>
    </MDBContainer>
  );
};

export { GenerateReport };
