import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import { Chart } from 'react-charts';
import {
  MealResponse,
  UserSurveyFormItem,
  UserSurveyResponseItem,
  UserSurveyResponseItemValue,
  UserSurveyResponseResponse,
} from '../api/generated/models';
import { getDurationFilters, withFilterPanel } from '../components/emotionsFilter';
import { flattenFeedEntries, getMealResponseByMealId } from '../components/helpers/mealHelpers';
import { LoadingSpinner } from '../components/loadingSpinner';
import { isEmoji, usePatientSurvey } from '../components/patientSurvey';
import { RxErrorBoundary } from '../components/RxErrorBoundary';
import { useCurrentPatientData, useStore } from '../context';
import { Trans, useTranslation } from '../i18n';
import { PatientService } from '../services/patient';
import { formatAMPM, formatDate } from '../utils/formatDate';
import { getEmotionOptions } from '../utils/userDataTranslations';
import './patient-emotions.scss';
import { ShowMealV2 } from '../components/showMealV2';
import { titleCase } from '../utils/formatters';
import { usePatientActivityFeed } from './patient-logV2';

type EmotionsProps = {
  filter: ReturnType<typeof getDurationFilters>[number],
  since: moment.Moment,
  until: moment.Moment,
};

const Emotions = (props: EmotionsProps) => {
  const { flags } = useCurrentPatientData();
  return (
    <>
      <div style={{ height: '20px' }} />
      {flags.patient_app_mindful_eating_survey && (
        <>
          <MindfulEatingTable {...props} />
          <div style={{ height: '50px' }} />
        </>
      )}
      <RxErrorBoundary>
        <_Emotions {...props} />
      </RxErrorBoundary>
    </>
  );
};

type TableColumn = {
  id: string,
  label: string,
  label_translations: any,
  minWidth?: string,
  isEmoji?: boolean,
};

type TableRow = {
  date: string,
  time: string,
  meal_id: number,
  meal: MealResponse,
  food_name: string[],
  food_name_translations: object[],
  [key: string]: string | number | object,
};

const getSurveyResponse = (response: UserSurveyResponseResponse, formItemId: string): UserSurveyResponseItem => {
  return response?.survey_responses?.items?.find((r) => r.form_item_id === formItemId);
};

const getResponseValue = (
  formItem: UserSurveyFormItem,
  responseItem: UserSurveyResponseItem | undefined,
  isTranslations: boolean,
) => {
  if (!responseItem?.value) {
    return '–';
  }
  const formatValue = (value: UserSurveyResponseItemValue, isMulti: boolean, isTranslations: boolean) => {
    if (!('options' in formItem.widget)) {
      return value;
    }
    if (isMulti) {
      if (!Array.isArray(value)) {
        return value;
      }
      const options = formItem.widget.options;
      return isTranslations
        ? value.map(v => options?.find(opt => opt.value === v)?.label_translations)
        : value.map(v => options?.find(opt => opt.value == v)?.label || v);
    }
    const option = formItem.widget.options?.find(opt => opt.value === value);
    return isTranslations ? option?.label_translations : option?.label || value;
  };
  return formatValue(responseItem.value, formItem.widget.type === 'input-multicheck', isTranslations);
};

const TableRow = (props: { item: TableRow, columns: TableColumn[], setSelectedMeal: (meal: MealResponse) => void }) => {
  const { tAttr, t } = useTranslation();

  const handleClick = () => {
    props.setSelectedMeal(props.item.meal);
  };

  const getFoodName = (item: TableRow) => {
    return (item.food_name || []).map((name, idx) => {
      const translationObj = {
        food_name: name,
        food_name_translations: item.food_name_translations[idx],
      };
      return titleCase(tAttr(translationObj, 'food_name'));
    }).join(', ');
  };
  const getSurveyValue = (item: TableRow, id: string) => {
    if (!Array.isArray(item[id])) {
      return tAttr(item as any, id);
    }
    return (item[id] || []).map((value: string, idx: number) => {
      const translationObj = {
        [id]: value,
        [`${id}_translations`]: item[`${id}_translations`]?.[idx],
      };
      return tAttr(translationObj, id);
    }).join(', ');
  };
  return (
    <tr>
      {props.columns.map((col) => {
        const foodName = col.id === 'food_name' && getFoodName(props.item);
        return col.id === 'food_name'
          ? (
            <td key={col.id} style={{ minWidth: col.minWidth }}>
              <span
                onClick={() => foodName && props.setSelectedMeal(props.item.meal)}
                style={foodName ? { cursor: 'pointer', textDecoration: 'underline' } : undefined}
              >
                {foodName || t('Unknown')}
              </span>
            </td>
          )
          : (
            <td key={col.id} style={{ minWidth: col.minWidth }}>
              <span style={col.isEmoji ? { fontSize: '24px' } : undefined}>
                {getSurveyValue(props.item, col.id)}
              </span>
            </td>
          );
      })}
    </tr>
  );
};

const MindfulEatingTable = (props: EmotionsProps) => {
  const { t, tAttr } = useTranslation();
  const { patient } = useStore();
  const [selectedMeal, setSelectedMeal] = useState<MealResponse | null>(null);
  const surveyQuery = usePatientSurvey({
    patientId: patient.patient_id,
    surveyType: 'mindful-eating',
    relatedActivityType: 'meal-log',
  });
  const surveyForm = surveyQuery.form?.survey_form;
  const patientActivityFeedQuery = usePatientActivityFeed({
    patientId: patient.patient_id,
    filterOptions: { logType: { 'has_mindful_eating_survey': true, 'meal-log': true } },
  });

  const columns = useMemo(() => {
    const cols = [
      { id: 'date', label: t('Log Day'), label_translations: null, minWidth: '100px' },
      { id: 'food_name', label: t('Meal'), label_translations: null, minWidth: '150px' },
    ];
    const question_cols = !!surveyForm?.items.length
        && surveyForm?.items.slice(1).map((item): TableColumn => ({
          id: item.id,
          label: item.label_short || item.label,
          label_translations: item.label_short_translations || item.label_translations,
          minWidth: item.widget.type === 'input-radio' || item.widget.type === 'input-scale' ? '120px' : '150px',
          isEmoji: item.widget.type === 'input-radio' && isEmoji(item.label),
        })) || [];
    return [...cols, ...question_cols];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyForm?.items]);

  const tableData = useMemo(() => {
    if (!surveyQuery.response || !surveyForm?.items) {
      return [];
    }
    const responses = surveyQuery.response as UserSurveyResponseResponse[];
    return responses.filter((response) => {
      const responseDate = moment(response.created_time);
      return responseDate >= props.since && responseDate <= props.until;
    }).map((response) => {
      const meal = getMealResponseByMealId(
        response.related_activity_id,
        flattenFeedEntries(patientActivityFeedQuery.feedEntries),
      );
      const initialRowData = {
        date: formatDate(response.created_time),
        time: formatAMPM(response.created_time),
        meal_id: meal?.id ?? null,
        meal: meal,
        food_name: meal?.meal_items?.map(item => item.food_name) ?? [],
        food_name_translations: meal?.meal_items?.map(item => item.food_name_translations) ?? [],
      };

      if (!surveyForm?.items) {
        return initialRowData;
      }
      return surveyForm.items.slice(1).reduce((acc, formItem) => {
        const responseItem = getSurveyResponse(response, formItem.id);
        return {
          ...acc,
          [formItem.id]: getResponseValue(formItem, responseItem, false),
          [formItem.id + '_translations']: getResponseValue(formItem, responseItem, true),
        };
      }, initialRowData);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyQuery.response, surveyForm?.items, patientActivityFeedQuery.feedEntries, props.filter]);

  return (
    surveyQuery.responseQuery.isLoading
      ? <LoadingSpinner />
      : surveyQuery.responseQuery.isError
      ? <div>{t('Error loading survey data')}</div>
      : !!tableData.length && (
        <>
          <div>
            <div className="goalDetail" style={{ fontSize: '28px' }}>
              {tAttr(surveyForm.items[0], 'label')}
            </div>
            <div style={{ overflowX: 'scroll' }}>
              <Table className="patientDataTable">
                <thead>
                  <tr>
                    {columns.map((col) => (
                      <td
                        key={col.id}
                        style={{ minWidth: col.minWidth }}
                      >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          {tAttr(col, 'label')} {col.id === 'how-present' && t(' (1 – 5)')}
                        </div>
                      </td>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {!tableData?.length
                    ? (
                      <tr>
                        <td colSpan={columns.length}>{t('No emotion data available')}</td>
                      </tr>
                    )
                    : (tableData.map((item) =>
                      item.date
                      && (
                        <TableRow
                          key={`${item.date}+${item.time}`}
                          item={item}
                          columns={columns}
                          setSelectedMeal={setSelectedMeal}
                        />
                      )
                    ))}
                </tbody>
              </Table>
            </div>
          </div>
          {!!selectedMeal && (
            <ShowMealV2
              selectedMeal={selectedMeal}
              activityData={flattenFeedEntries(patientActivityFeedQuery.feedEntries)}
              onHide={() => setSelectedMeal(null)}
              initialMode="view"
            />
          )}
        </>
      )
  );
};

const _Emotions = (props: EmotionsProps) => {
  const { emotion, emotionQuestion, patient } = useStore();
  const patientService = PatientService();
  const { t } = useTranslation();
  const { filter } = props;

  useEffect(() => {
    const patient_id = patient.patient_id;
    const getDataEmotion = async () => {
      const [since, until] = filter[0].map(m => moment(m).format('YYYY-MM-DD'));
      await patientService.getEmotion(patient_id, since, until);
    };
    getDataEmotion();

    const getDataEmotionQuestion = async () => {
      await patientService.getEmotionQuestion(patient_id);
    };
    getDataEmotionQuestion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  function outputStatementQuestion() {
    if (emotionQuestion.length == 0) {
      return t('Loading');
    }
    return (
      <div className="goalDetail">
        <Trans>Emotion Logs</Trans>
      </div>
    );
  }

  function outputStatement(status, questions) {
    // if (emotion.length == 0 || emotionQuestion.length == 0) {
    //   return '';
    // }

    return (
      <div>
        {PlotDataEmotion(questions, status)}
        <br />
        <Row>
          {!!questions?.options?.length && questions.options.map((q, idx) => {
            const e1 = status.filter(status => status.emotion === q);
            return (
              <Col key={idx} className="GoalCol summaryTitle last">
                {getEmotionOptions(t).find(o => o.id === q)?.labelTr}
                <br />
                <Trans>N=</Trans>
                {e1.length}
              </Col>
            );
          })}
        </Row>
        <p className="goalTitle" />
        <Table className="patientDataTable">
          <thead>
            <tr>
              <td>
                <Trans>Log Day</Trans>
              </td>
              <td>
                <Trans>Emotion</Trans>
              </td>
              <td>
                <Trans>Notes</Trans>
              </td>
            </tr>
          </thead>
          <tbody>
            {status?.length == 0
              ? (
                <tr>
                  <td colSpan={3}>{t('No emotion data available')}</td>
                </tr>
              )
              : (
                status?.map((item, idx) => {
                  return (
                    <tr key={idx}>
                      <td style={{ minWidth: '100px' }}>{item.log_date}</td>
                      <td style={{ minWidth: '100px' }}>
                        {getEmotionOptions(t).find(o => o.id === item.emotion)?.labelTr || item.emotion}
                      </td>
                      <td style={{ minWidth: '100px' }}>{item.note}</td>
                    </tr>
                  );
                })
              )}
          </tbody>
        </Table>
      </div>
    );
  }

  function PlotDataEmotion(dataD1, dataD2) {
    const plotPointsEmotion = [];
    if (dataD1 && dataD2) {
      dataD1 = dataD1.options;
      let countData = 1;
      dataD1?.map(q => {
        let EmotionCount = 0;
        dataD2?.map(item => {
          if (item.emotion == q) {
            EmotionCount++;
          }
        });
        plotPointsEmotion.push([countData, EmotionCount]);
        countData++;
      });
    }
    const dataEmotion = // React.useMemo(
      // () =>
      [
        {
          label: t('Emotions'),
          data: plotPointsEmotion,
        },
      ]; // ,
    // []
    // );

    const seriesEmotion = // React.useMemo(
      // () =>
      {
        type: t('bar'),
      }; // ,
    // []
    // );

    const axesEmotion = // React.useMemo(
      // () =>
      [
        { primary: true, type: 'ordinal', position: 'bottom', show: false },
        { type: 'linear', position: 'left', hardMin: 0 },
      ]; // ,
    // []
    // );

    const barChart = (
      <div
        style={{
          padding: '10px',
          width: '100%',
          height: '300px',
        }}
      >
        <Chart id="emotionPlot" data={dataEmotion} series={seriesEmotion} axes={axesEmotion} tooltip />
      </div>
    );

    return (
      <div>
        {!!dataD2.length && barChart}
      </div>
    );
  }

  return (
    <div>
      <div>
        {outputStatementQuestion()}
        {outputStatement(emotion, emotionQuestion)}
      </div>
    </div>
  );
};

export default withFilterPanel(Emotions);
